import {useAdminData} from '../../../context/AdminDataContext'
import {UserRole} from '../../../context/UserContext'
import SelectList from '../../Common/SelectList'
import {useLocationsUsers} from './useLocationsUsers'

const RoleColors = {
	[UserRole.user]: '#ffffff',
	[UserRole.vip]: '#fab465',
	[UserRole.admin]: '#00e6ff',
}

const UserEntry = ({id, onClick}) => {
	const [db] = useAdminData()
	const user = db.users[id]

	return (
		<div style={{color: RoleColors[user.role]}} className='user-link' onClick={onClick}>
			{user.username}
			<span className='btn-delete-user'>delete</span>
		</div>
	)
}

const UsersView = ({locationId, onAdd, onRemove}) => {
	const [db] = useAdminData()

	const locationUsers = useLocationsUsers()[locationId]
	const availableUsers = Object.keys(db.users).filter((userId) => !locationUsers.includes(userId))

	const options = availableUsers.map((userId) => ({value: userId, label: db.users[userId].username}))

	const handleSelect = (id) => {
		onAdd(id)
	}

	return (
		<>
			<SelectList options={options} onSelect={handleSelect} id={'users'} defaultTitle='Add new user' />
			{locationUsers.map((id) => (
				<div key={id}>
					<UserEntry
						id={id}
						onClick={(event) => {
							event.preventDefault()
							onRemove(id)
						}}
					/>
				</div>
			))}
		</>
	)
}

export {UsersView}
