import React, {useCallback, useEffect, useState} from 'react'
import {useAdminData} from '../../../context/AdminDataContext'

import DataService from '../../../services/DataService'
import {useAsync} from '../../../utils/useAsync'
import {ConfirmationWindow} from '../Utils/ConfirmationWindow'
import {LocationsAndUsersTable} from './LocationsAndUsersTable'


function add(userId, locationId) {
	return DataService.addLocationToUser({
		locationId: locationId,
		userId: userId,
	})
}

function remove(userId, locationId) {
	return DataService.rmLocationFromUser({
		locationId: locationId,
		userId: userId,
	})
}

const UserAction = {
	add: add,
	remove: remove,
}

const LocationsAndUsers = () => {
	const [db] = useAdminData()
	const asyncFn = useAsync()

	const [showConfirmation, setShowConfirmation] = useState(false)
	const handleClose = () => {
		setShowConfirmation(false)
	}

	const [error, setError] = useState(null)

	const [isLoading, setIsLoading] = useState(false)
	const handleLoaded = () => {
		setIsLoading(false)
	}

	const [data, setData] = useState(null)

	const setAction = (userId, locationsId, actionType) => {
		setData({locationId: locationsId, userId: userId, actionType: actionType})
	}

	const onAdd = (userId, locationsId) => {
		setAction(userId, locationsId, 'add')
	}

	const onRemove = (userId, locationsId) => {
		setAction(userId, locationsId, 'remove')
	}

	const handleConfirm = useCallback(() => {
		const {locationId, userId, actionType} = data

		setIsLoading(true)

		asyncFn(UserAction[actionType](userId, locationId)).then(handleClose).catch(setError).finally(handleLoaded)
		//unnecessary dependency: 'db'.
		// eslint-disable-next-line
	}, [db, data, asyncFn])

	useEffect(() => {
		setError(null)
		setIsLoading(false)
	}, [showConfirmation])

	useEffect(() => {
		if (!data) return
		setShowConfirmation(true)
	}, [data])

	return (
		<>
			<LocationsAndUsersTable onAdd={onAdd} onRemove={onRemove} />
			<ConfirmationWindow
				show={showConfirmation}
				isLoading={isLoading}
				error={error}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	)
}

export {LocationsAndUsers}

