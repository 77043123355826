import {useCallback, useEffect, useRef} from 'react'

const useMountedState = () => {
	const mountedRef = useRef(false)
	const isMounted = useCallback(() => mountedRef.current, [])
	useEffect(() => {
		mountedRef.current = true

		return () => {
			mountedRef.current = false
		}
	}, [])

	return isMounted
}

const useAsync = () => {
	const isMounted = useMountedState()
	return useCallback(
		(promise) =>
			new Promise((resolve, reject) => {
				const onFulfilled = (value) => {
					isMounted() && resolve(value)
				}
				const onReject = (error) => {
					isMounted() && reject(error)
				}

				promise.then(onFulfilled, onReject)
			}),
		[isMounted]
	)
}

export {useAsync}
