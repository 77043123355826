import React, {createContext, useContext} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {useUser} from './context/UserContext'
import {Helmet, HelmetProvider} from 'react-helmet-async'

// import logo from '../build/images/logo_wh.svg'
import Footer from './components/Common/Footer'
import Header from './components/Common/Header'

// import Offline from './components/ServicePages/Offline'

function deleteSimilarPaths(routes, userRole) {
	const map = {}
	routes.forEach((route) => {
		const key = route.path
		map[key] = map[key] || 1
		if (route.accessList.includes(userRole)) map[key]++
	})

	routes = routes.filter((route) => map[route.path] <= 1 || route.accessList.includes(userRole))

	return routes
}

const NavigationContext = createContext()

const RouteResolver = ({routes, defaults}) => {
	const user = useUser()
	const userRole = user.role

	if (routes.find((route) => route.path === '/')) {
		throw new Error('Path "/" is reserved')
	}

	routes = deleteSimilarPaths([...routes], userRole)

	return (
		<HelmetProvider>
			<NavigationContext.Provider
				value={routes.filter((route) => route.accessList.includes(userRole) && route.isNavigable)}>
				<Routes>
					<Route exact path='/' element={<Navigate replace to={defaults[userRole]} />} />
					{routes.map((route) => {
						const content = route.accessList.includes(userRole) ? (
							<>
								<Helmet>
									<title>{route.title}</title>
								</Helmet>
								<route.component />
							</>
						) : (
							<Navigate to={defaults[userRole]} />
						)

						return (
							<Route
								key={route.path}
								exact
								path={route.path}
								element={
									<>
										<div className='page'>
											{route.showHeader && <Header />}
											<div className='page-content'>{content}</div>
											{route.showFooter && <Footer />}
										</div>
									</>
								}
							/>
						)
					})}
				</Routes>
			</NavigationContext.Provider>
		</HelmetProvider>
	)
}

const useNavigation = () => {
	const context = useContext(NavigationContext)
	if (context === undefined) {
		throw new Error('useNavigation must be used inside component passed to RouteResolver')
	}
	return context
}

export {useNavigation, RouteResolver}
