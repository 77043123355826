import {useState} from 'react'
import Chart from 'react-apexcharts'
import {useCookedStatistics} from '../../../../context/CookedStatisticsContext'
import {useMemo} from 'react'
import SelectList from '../../../Common/SelectList'
// import moment from 'moment'

function transformMachineEvents(events) {
	const transformed = []
	events.forEach((event) => {
		transformed.push({
			type: 'start',
			ts: event.timeStarted,
			gameName: 'game',
		})
		transformed.push({
			type: 'stop',
			ts: event.timeStopped,
		})
	})

	return transformed.sort((a, b) => a.ts - b.ts)
}

const unixMinute = 60 * 1000
const timezoneOffset = new Date().getTimezoneOffset() * unixMinute

function toLocal(ts) {
	return ts - timezoneOffset
}

function machineEventsToTimeline(events) {
	let currValue = 0
	const timeline = new Array(events.length)

	events.forEach((event, index) => {
		currValue += event.type === 'start' ? 1 : -1
		timeline[index] = [toLocal(event.ts), currValue]
	})

	return timeline
}

function calculateOverallActivity(machineEvents) {
	// const values = [
	//     {
	//         name: 'Location 1',
	//         data: [
	//             [3600000, 0],
	//             [7200000, 0],
	//             ...
	//         ]
	//     },
	//     {
	//         name: 'Location 2',
	//         data: [
	//             [3600000, 0],
	//             [7200000, 0],
	//             ...
	//         ]
	//     },
	// ]

	return {
		data: machineEventsToTimeline(transformMachineEvents(machineEvents)),
	}
}

function cloverEventsToAnnotations(events) {
	return events.map((event) => ({
		x: toLocal(event.createdTime),
		borderColor: '#775DD0',
		label: {
			style: {
				background: '#333',
				color: '#fff',
				fontSize: '12px',
				fontWeight: 400,
				fontFamily: 'Roboto',
			},
			text: event.amount / 100 + '$',
			orientation: 'horizontal',
		},
	}))
}

const OverallActivityChart = () => {
	const [locationId, setLocationId] = useState(0)

	const handleSelect = (id) => {
		if (locationId !== id) setLocationId(id)
	}

	const stats = useCookedStatistics()

	const activity = useMemo(() => calculateOverallActivity(stats?.[locationId]?.machineEvents || []), [stats, locationId])

	const annotations = useMemo(
		() => cloverEventsToAnnotations(stats?.[locationId]?.cloverEvents || []),
		[stats, locationId]
	)

	const options = {
		annotations: {
			xaxis: annotations,
		},
		chart: {
			foreColor: '#ccc',
			toolbar: {
				show: true,
				tools: {
					download: false,
				},
				offsetY: '-22',
			},
			zoom: {
				enabled: true,
			},
		},
		stroke: {
			width: 1,
			curve: 'stepline',
		},
		grid: {
			yaxis: {
				lines: {
					show: false,
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		fill: {
			type: 'solid',
		},
		markers: {
			size: 0,
		},
		xaxis: {
			type: 'datetime',
		},
		tooltip: {
			theme: 'dark',
			x: {
				format: 'HH:mm',
			},
			y: {
				formatter: (val) => Number(val).toFixed(0),
				title: {
					formatter: () => 'Active machines: ',
				},
			},
		},
		yaxis: {
			show: false,
		},
		colors: ['#00c896'],
		noData: {
			text: 'No data',
		},
	}

	return (
		<>
			<SelectList
				options={stats?.map((item, index) => ({label: item.locationName, value: index})) || []}
				selectedByDefault={!!stats && locationId}
				onSelect={handleSelect}
			/>
			<Chart options={options} series={[activity]} type='line' height='100%' />
		</>
	)
}

export {OverallActivityChart}
