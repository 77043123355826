import {useEffect} from 'react'

import ChangePassword from './ChangePassword/ChangePassword'
// import BindLocationsToClover from './BindLocationsToClover/BindLocationsToClover'
import GeneralInformation from './GeneralInformation/GeneralInformation'
// import {useUserData} from '../../../context/UserDataContext'
import ProfileAccordion from './ProfileAccordion/ProfileAccordion'
import DataService from '../../../services/DataService'

const UserProfile = () => {
	// const [locations] = useUserData()
	const entries = [
		{
			title: 'General',
			component: GeneralInformation,
		},
		{
			title: 'Change password',
			component: ChangePassword,
		},
		// {
		// 	title: 'Bind locations to Clover',
		// 	component: BindLocationsToClover,
		// },
	]

	const hash = Number(window.location.hash.substr(1))
	const openedPage = 0 <= hash && hash < entries.length ? hash : 0

	async function sendData(data) {
		// console.log('searchToObject:', data)
		// console.log('update sending')
		// const res =
		await DataService.sendCloverData(data)
		// console.log('res', res)
	}

	useEffect(() => {
		async function update(data) {
			await sendData(data)
		}

		if (window.location.search.length > 0) {
			const data = searchToObject(window)

			update(data)
			if (window.history.replaceState) {
				let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname
				window.history.replaceState({path: newurl}, '', newurl)
			} else window.location.search = ''
		}
	}, [])

	return (
		<div className='profile-page'>
			<ProfileAccordion entries={entries} page={openedPage} />
		</div>
	)
}

function searchToObject(window) {
	var pairs = window.location.search.substring(1).split('&'),
		obj = {},
		pair,
		i

	for (i in pairs) {
		if (pairs[i] === '') continue

		pair = pairs[i].split('=')
		obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
	}

	return obj
}
export default UserProfile
