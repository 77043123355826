import {createContext, useContext, useEffect, useState} from 'react'

import DataService from '../services/DataService'

import {useAsync} from '../utils/useAsync'
import {useTopic} from './SocketContext'

const AdminDataContext = createContext()

const AdminDataProvider = ({children}) => {
    const updates = useTopic('adminUpdate')
    const [db, setDB] = useState(null)

    const [isLoading, setIsLoading] = useState(true)

    const handleLoaded = () => {
        setIsLoading(false)
    }

    const asyncFn = useAsync()

    useEffect(() => {
        // TODO: Can we receive data after updates?
        asyncFn(DataService.adminGetAll()).then(setDB).catch(console.log).finally(handleLoaded)
        // eslint-disable-next-line
    }, [])

    const categories = ['users', 'machines', 'locations', 'games']

    useEffect(() => {
        if (!updates) return

        setDB((prevDB) => {
            const newDB = {}

            categories.forEach((category) => {
                const data = {...prevDB?.[category], ...updates[category]}
                for (const key in data) {
                    if (data[key] == null) {
                        delete data[key]
                    }
                }
                newDB[category] = data
            })

            return newDB
        })
        // eslint-disable-next-line
    }, [updates]) //categories

    return <AdminDataContext.Provider value={[db, isLoading]}>{children}</AdminDataContext.Provider>
}

const useAdminData = () => {
    const context = useContext(AdminDataContext)
    if (context === undefined) {
        throw new Error('useAdminData must be used within a AdminDataProvider')
    }

    return context
}

export {AdminDataProvider, useAdminData}
