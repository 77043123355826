import {useState} from 'react'
import {Alert, Button, Form} from 'react-bootstrap'
import {Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import DataService from '../../../../services/DataService'
import './ChangePassword.css'

const Schema = Yup.object().shape({
	passwordOld: Yup.string().required('Password is required'),
	passwordNew: Yup.string()
		.min(8, 'Password must be between 8 and 30 characters')
		.max(30, 'Password must be between 8 and 30 characters')
		.required('Password is required'),
	passwordConfirm: Yup.string()
		.oneOf([Yup.ref('passwordNew'), null], 'Password must match')
		.required('Password confirmation is required'),
})

const ChangePassword = () => {
	const [error, setError] = useState('')

	const [passwordOld, setPasswordOld] = useState('')
	const [passwordNew, setPasswordNew] = useState('')
	const [passwordConfirm, setPasswordConfirm] = useState('')

	const [alertEnabled, setAlertEnabled] = useState(false)

	const showAlert = () => {
		setAlertEnabled(true)
	}
	const closeAlert = () => {
		setAlertEnabled(false)
	}

	return (
		<div className='change-password-form-container'>
			<Formik
				initialValues={{
					passwordOld: '',
					passwordNew: '',
					passwordConfirm: '',
				}}
				validationSchema={Schema}
				onSubmit={(values, {setSubmitting, resetForm}) => {
					setSubmitting(true)
					showAlert()
					DataService.changePassword({
						oldPassword: values.passwordOld,
						newPassword: values.passwordNew,
					})
						.then((res) => {
							console.log(res)
							closeAlert()
							resetForm()
							setPasswordNew('')
							setPasswordOld('')
							setPasswordConfirm('')
						})
						.catch(setError, showAlert)
						.finally(() => {
							setSubmitting(false)
						})
				}}>
				{({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
					<>
						<Form noValidate onSubmit={handleSubmit}>
							{/* {[
								{title: 'Old password', name: 'passwordOld'},
								{title: 'New password', name: 'passwordNew'},
								{title: 'Confirm password', name: 'passwordConfirm'},
							].map((it) => (
								<Form.Group>
									<Form.Label>{it.title}</Form.Label>
									<Form.Control
										type='text'
										name={it.name}
										placeholder='Password'
										onChange={(value) => {
											setName(value.target.value)
										}}
										onBlur={handleBlur}
										value={values.password}
										isInvalid={touched[it.name] && errors[it.name]}
										isValid={touched[it.name] && !errors[it.name]}
									/>
									{touched[it.name] && errors[it.name] ? (
										<div className='form-error-message'>*{errors[it.name]}</div>
									) : null}
								</Form.Group>
							))} */}
							<Form.Group className='form-group'>
								<Form.Label>{'Old password'}</Form.Label>
								<Form.Control
									type='text'
									name='passwordOld'
									placeholder='Password'
									onChange={(value) => {
										handleChange(value)
										setPasswordOld(value.target.value)
									}}
									onBlur={handleBlur}
									value={passwordOld}
									isInvalid={touched.passwordOld && errors.passwordOld}
									isValid={touched.passwordOld && !errors.passwordOld}
								/>
								{touched.passwordOld && errors.passwordOld ? (
									<div className='form-error-message'>*{errors.passwordOld}</div>
								) : null}
							</Form.Group>

							<Form.Group className='form-group'>
								<Form.Label>{'New password'}</Form.Label>
								<Form.Control
									type='text'
									name={'passwordNew'}
									placeholder='Password'
									onChange={(value) => {
										setPasswordNew(value.target.value)
										handleChange(value)
									}}
									onBlur={handleBlur}
									value={passwordNew}
									isInvalid={touched.passwordNew && errors.passwordNew}
									isValid={touched.passwordNew && !errors.passwordNew}
								/>
								{touched.passwordNew && errors.passwordNew ? (
									<div className='form-error-message'>*{errors.passwordNew}</div>
								) : null}
							</Form.Group>

							<Form.Group className='form-group'>
								<Form.Label>{'Confirm password'}</Form.Label>
								<Form.Control
									type='text'
									name={'passwordConfirm'}
									placeholder='Password'
									onChange={(value) => {
										handleChange(value)
										setPasswordConfirm(value.target.value)
									}}
									onBlur={handleBlur}
									value={passwordConfirm}
									isInvalid={touched.passwordConfirm && errors.passwordConfirm}
									isValid={touched.passwordConfirm && !errors.passwordConfirm}
								/>
								{touched.passwordConfirm && errors.passwordConfirm ? (
									<div className='form-error-message'>*{errors.passwordConfirm}</div>
								) : null}
							</Form.Group>

							<Button
								variant='login'
								type='submit'
								disabled={isSubmitting}
								onClick={() => {
									document.activeElement.blur()
								}}>
								{isSubmitting && <span className='spinner-border spinner-border-sm' />}
								Submit
							</Button>

							{alertEnabled && error && (
								<Alert
									// show={!!error}
									onClose={closeAlert}
									variant='danger'
									//dismissible
								>
									{error}
								</Alert>
							)}
						</Form>
					</>
				)}
			</Formik>
		</div>
	)
}

export default ChangePassword
