import requestFactory from './RequestFactory'

const DataService = {
    adminGetAll: requestFactory.post('/adminGetAll'),
    userGetAll: requestFactory.post('/userGetAll'),
    getRawStats: requestFactory.post('/stats/getRawStats'),
    downloadStats: requestFactory.post('/stats/download'),

    clearMachineLocation: requestFactory.patch('/machines/rmLocation'),

    addMachineToLocation: requestFactory.patch('/locations/addMachine'),
    addMachineToLocationForce: requestFactory.patch('/locations/addMachineForce'),
    newLocation: requestFactory.post('/locations/newLocation'),
    removeLocation: requestFactory.post('/locations/removeLocation'),
    editLocation: requestFactory.post('/locations/editLocation'),
    addLocationToUser: requestFactory.patch('/locations/addToUser'),
    rmLocationFromUser: requestFactory.patch('/locations/rmFromUser'),

    sendCloverData: requestFactory.post('/clover/addCloverAuth'),
    unbindCloverData: requestFactory.post('/clover/unbindClover'),
    addDevicesToLocation: requestFactory.post('/clover/addDevicesToLocation'),
    // refreshCloverDevices: requestFactory.post('/clover/refreshCloverDevices'),

    startPasswordRestore: requestFactory.post('/users/startPasswordRestore'),
    setNewPassword: requestFactory.post('/users/setNewPassword'),
    changePassword: requestFactory.post('/users/resetPassword'),
    changeUserRole: requestFactory.post('/users/changeRole'),
    updateUserToken: requestFactory.post('/users/requestToken'),
    deleteUser: requestFactory.post('/users/deleteUser'),

    //software
    getLatestSoftware: requestFactory.post('/software/getLatest'),
    setLatestSoftware: requestFactory.post('/software/setLatest'),
}

export default DataService
