import {createContext, useCallback, useContext, useEffect, useState} from 'react'
import DataService from '../services/DataService'
import moment from 'moment'
import {useUserStorage} from '../utils/useUserStorage'
import {useAsync} from '../utils/useAsync'

const StatisticsLoaderContext = createContext()

const unixHour = 60 * 60 * 1000

const DB_EXPIRATION_TIME = unixHour
const STATS_LOCAL_STORAGE_KEY = 'stats'

async function fetchStats(startDate, endDate) {
	const formatDate = (date) => moment(date).format('DD-MM-YYYY')

	return DataService.getRawStats({startTime: formatDate(startDate), endTime: formatDate(endDate)})
}

const StatisticsProvider = ({children}) => {
	const [saveStats, loadStats] = useUserStorage(STATS_LOCAL_STORAGE_KEY)
	const asyncFn = useAsync()
	const [db, setDB] = useState(loadStats())

	const saveLoadedStats = useCallback(
		(data) => {
			saveStats(data)
			setDB(data)
		},
		[saveStats]
	)

	const loader = useCallback(
		(fromDate, toDate) => {
			const nowDate = new Date()
			if (!fromDate) {
				fromDate = nowDate
			}
			if (!toDate) {
				toDate = nowDate
			}

			if (db) {
				const isLowerDatePresented = db.service.ts_from <= moment(fromDate).valueOf()
				const isLateDatePresented = db.service.ts_to >= moment(toDate).valueOf() - DB_EXPIRATION_TIME

				if (isLowerDatePresented && isLateDatePresented) {
					return Promise.resolve(db)
				}
			}

			return asyncFn(fetchStats(fromDate, toDate)).then(
				(data) => {
					saveLoadedStats(data)
					return data
				},
				(error) => console.log('error fetching db:', error) || null
			)
		},
		[db, asyncFn, saveLoadedStats]
	)

	const initialize = useCallback(() => {
		if (db !== null) return
		loader()
	}, [db, loader])

	useEffect(() => {
		initialize()
	}, [initialize])

	return <StatisticsLoaderContext.Provider value={loader}>{children}</StatisticsLoaderContext.Provider>
}

const useStatisticsLoader = () => {
	const context = useContext(StatisticsLoaderContext)
	if (context === undefined) {
		throw new Error('useStatistics must be used within a StatisticsProvider')
	}

	return context
}

export {StatisticsProvider, useStatisticsLoader}
