import {Dropdown} from 'react-bootstrap'
import DropdownFilterableList from './DropdownFilterableList'

const SelectList = ({
	options /* [{value, label},...] */,
	selectedByDefault /* value */,
	onSelect,
	defaultTitle,
	id,
}) => {
	const activeEntryIndex = options.findIndex((item) => item.value === selectedByDefault)

	const handleSelect = (index) => {
		if (index === activeEntryIndex) {
			return
		}

		onSelect(options[index].value)
	}

	const title =
		selectedByDefault !== undefined && activeEntryIndex !== -1
			? options[activeEntryIndex].label
			: defaultTitle !== undefined
			? defaultTitle
			: 'Select'

	return (
		<DropdownFilterableList header={title} onSelect={handleSelect} settings={{share: !!id, id: id}}>
			{
				//  Strange behaviour
				//  Spaces between tags <tag> {'text'} </tag> make 3 child in node
				//  Array[3] = ['', 'text', '']
				options.map((entry, index) => (
					<Dropdown.Item
						className='enable-text-overflow'
						key={options[index].value}
						eventKey={index}
						active={activeEntryIndex === index}>
						{options[index].label}
					</Dropdown.Item>
				))
			}
		</DropdownFilterableList>
	)
}

export default SelectList
