import {useRef} from 'react'
import BasicAuthPage from './BasicAuthPage'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import AuthService from '../../services/AuthService'

const Register = () => {
	const navigate = useNavigate()
	const success = useRef(false)

	return (
		<BasicAuthPage
			title='Register'
			buttonText='Register'
			onSubmit={async ({username, email, password}) => {
				await AuthService.register(username, email, password)
				success.current = true
			}}
			postSubmit={() => {
				success.current && navigate('/')
			}}
			footer={
				<>
					Already have account? <Link to='login'>Sign in</Link>
				</>
			}
			postFormWidget={
				<div className='register-notification'>
					By clicking "Register", you agree to our <Link to='terms'>Terms of Use </Link>
					and that you have read our <Link to='/privacy'>Privacy Policy</Link>
				</div>
			}
			forms={[
				{
					title: 'Email',
					placeholder: 'Email',
					type: 'email',
					name: 'email',
					value: '',
					validator: Yup.string()
						.email('Must be a valid email address')
						.max(100, 'Email must be less than 100 characters')
						.required('Email is required'),
				},
				{
					title: 'Username',
					placeholder: 'Username',
					type: 'text',
					name: 'username',
					value: '',
					validator: Yup.string().required('Username is required'),
				},
				{
					title: 'Password',
					placeholder: 'Password',
					type: 'password',
					name: 'password',
					value: '',
					validator: Yup.string()
						.min(8, 'Password must be between 8 and 30 characters')
						.max(30, 'Password must be between 8 and 30 characters')
						.required('Password is required'),
				},
				{
					title: '',
					placeholder: 'Confirm password',
					type: 'password',
					name: 'passwordConfirmation',
					value: '',
					validator: Yup.string()
						.oneOf([Yup.ref('password'), null], 'Password must match')
						.required('Password confirmation is required'),
				},
			]}
		/>
	)
}

export default Register
