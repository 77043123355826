import DreamSimulator from '../machines/DreamSimulatorDark.svg'
import HighScream from '../machines/HighScream.svg'
import VRDinosaur from '../machines/VRDinosaur.svg'
import VRMotorcycle from '../machines/VRMotorcycleDark.svg'
import VRShotgun from '../machines/VRShotgun.svg'
import BabyShark from '../machines/BabyShark.svg'
import NotFoundSVG from '../machines/NotFoundSVG.svg'

const machineImages = {
    1: DreamSimulator,
    2: VRMotorcycle,
    3: VRShotgun,
    4: HighScream,
    5: VRDinosaur,
    6: BabyShark,
}

function machineCodeToImage(code) {
    const key = code.charAt(0)
    return machineImages[key] || NotFoundSVG
}

const MachineType = {
    DreamSimulator: 'DreamSimulator',
    VRMotorcycle: 'VRMotorcycle',
    VRShotgun: 'VRShotgun',
    HighScream: 'HighScream',
    VRDinosaur: 'VRDinosaur',
    BabyShark: 'BabyShark',
}

function machineCodeToName(code) {
    switch (true) {
        case /^1/.test(code):
            return MachineType.DreamSimulator
        case /^2/.test(code):
            return MachineType.VRMotorcycle
        case /^3/.test(code):
            return MachineType.VRShotgun
        case /^4/.test(code):
            return MachineType.HighScream
        case /^5/.test(code):
            return MachineType.VRDinosaur
        case /^6/.test(code):
            return MachineType.BabyShark
        default:
            return 'unknown'
    }
}

const MachineService = {
    machineCodeToImage,
    machineCodeToName,
    MachineType,
}
export default MachineService
