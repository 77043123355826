import React, {useState} from 'react'
import {Alert, Button, Form} from 'react-bootstrap'
import {Formik} from 'formik'
import * as Yup from 'yup'
import './BasicAuthPage.css'

const EmptyWidget = <></>

const BasicAuthPage = ({
	title,
	onSubmit,
	postSubmit,
	footer = EmptyWidget,
	forms,
	buttonText,
	postFormWidget = EmptyWidget,
}) => {
	const [result, setResult] = useState(null)
	const [alertEnabled, setAlertEnabled] = useState(false)
	const showAlert = () => {
		setAlertEnabled(true)
	}
	const closeAlert = () => {
		setAlertEnabled(false)
	}

	const unshapedSchema = {}
	forms.forEach((form) => {
		unshapedSchema[form.name] = form.validator
	})

	const validationSchema = Yup.object().shape(unshapedSchema)
	let initialValues = {termsAndPrivacy: false}
	forms?.forEach((form) => {
		initialValues[form.name] = form.value
	})

	return (
		<div className='auth-page-layout'>
			<Formik
				validationSchema={validationSchema}
				initialValues={initialValues}
				onSubmit={async (values, {setSubmitting}) => {
					setResult(null)
					setSubmitting(true)
					try {
						const data = await onSubmit?.(values, setResult)
						setResult({isSuccessful: true, message: data?.message || 'Success'})
					} catch (e) {
						setResult({isSuccessful: false, message: e})
					} finally {
						showAlert()
						setSubmitting(false)
						// process.nextTick(() =>
						postSubmit?.()
						// )
					}
				}}>
				{({handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting}) => (
					<div className='auth-page-form'>
						<div className='form-group'>
							<img className='auth-page-logo' width='100%' src={window.location.origin + '/images/logo_wh.svg'} alt='logo' />
						</div>

						<div className='form-group'>
							<div className='app-label'>{title}</div>
						</div>

						<Form noValidate onSubmit={handleSubmit}>
							{forms.map((form) => (
								<Form.Group className='form-group' key={form.name}>
									{!!form.title && <Form.Label>{form.title} :</Form.Label>}
									<Form.Control
										type={form.type}
										name={form.name}
										placeholder={form.placeholder}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values[form.name]}
										isInvalid={touched[form.name] && errors[form.name]}
										isValid={touched[form.name] && !errors[form.name]}
									/>
									{touched[form.name] && errors[form.name] ? (
										<div className='form-error-message'>*{errors[form.name]}</div>
									) : null}
								</Form.Group>
							))}

							{postFormWidget}

							{alertEnabled && result && result.message && (
								<Alert
									// dismissible
									onClose={closeAlert}
									variant={result.isSuccessful ? 'success' : 'danger'}>
									{result.message}
								</Alert>
							)}

							<Button
								variant='login'
								type='submit'
								disabled={isSubmitting}
								onClick={() => {
									document.activeElement.blur()
								}}>
								{isSubmitting && <span className='spinner-border spinner-border-sm' />}
								{buttonText}
							</Button>

							<p className='auth-page-footer link-painter'>{footer}</p>
						</Form>
					</div>
				)}
			</Formik>
		</div>
	)
}

export default BasicAuthPage
