import Chart from 'react-apexcharts'
// import ApexCharts from 'apexcharts'
import {useCookedStatistics} from '../../../../context/CookedStatisticsContext'
import {useMemo} from 'react'

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

function createApexChartsTimeScale() {
	// const values = [
	//     {
	//         name: 'Sunday',
	//         data: [
	//             [3600000, 0],
	//             [7200000, 0],
	//             ...
	//         ]
	//     },
	//     {
	//         name: 'Monday',
	//         data: [
	//             [3600000, 0],
	//             [7200000, 0],
	//             ...
	//         ]
	//     },
	// ]

	const data = []

	const unixHour = 60 * 60 * 1000

	for (let day = 0; day < daysOfWeek.length; day++) {
		const dayData = {name: daysOfWeek[day], data: []}

		for (let hour = 0; hour < 24; hour++) {
			dayData.data[hour] = [unixHour * (hour + 1), 0]
		}

		data[day] = dayData
	}

	return data
}

function calculateBusyHours(stats) {
	const values = createApexChartsTimeScale()

	stats.forEach((location) => {
		location.machineEvents.forEach(({eventType, timeStarted}) => {
			if (eventType !== 'stop') return
			const date = new Date(timeStarted)
			const day = date.getDay()
			const hour = date.getHours()
			values[day].data[hour][1]++
		})
	})

	return values
}

const BusyHoursChart = () => {
	const stats = useCookedStatistics()
	const busyHours = useMemo(() => calculateBusyHours(stats || []), [stats])

	const options = {
		chart: {
			id: 'hours',
			foreColor: '#ccc',
			toolbar: {
				show: true,
				tools: {
					download: false,
				},
			},
			zoom: {
				enabled: true,
			},
			/**
             events: {
                 mounted: function () {
                     const currentDay = new Date().toLocaleString('en-us', {weekday: 'long'})
                    // let colors = []
                    busyHours.forEach((stat) => {
                        // colors.push(getColor(stat.name))
                        if (stat.name === currentDay) return
                        ApexCharts.exec('hours', 'hideSeries', stat.name)
                        })
                    },
                },
            */
		},
		stroke: {
			width: 3,
			curve: 'smooth',
		},
		grid: {
			clipMarkers: false,
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		fill: {
			type: 'solid',
			opacity: 0.125,
		},
		markers: {
			size: 0,
		},
		xaxis: {
			type: 'datetime',
			labels: {
				format: 'HH:mm',
			},
		},
		tooltip: {
			theme: 'dark',
			x: {
				format: 'HH:mm',
			},
			y: {
				formatter: function (val) {
					return Number(val).toFixed(0) + ' games'
				},
			},
		},
		yaxis: {
			show: false,
			min: 0,
			tickAmount: 4,
		},
		colors: ['#FFFFFF', '#ed3f00', '#00ff00', '#FFB6C1', '#8B0000', '#261fff', '#000080'],
		legend: {
			showForZeroSeries: false,
		},
		noData: {
			text: 'No data',
		},
	}

	return <Chart options={options} series={busyHours} type='area' height='100%' />
}

export {BusyHoursChart}
