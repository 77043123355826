import React, {useEffect, useState} from 'react'
import {Alert, Button, Modal} from 'react-bootstrap'
import ForwardKeysToButtonClick from '../../Common/ForwardKeysToButtonClick'
import LoadingSpinner from '../../Common/LoadingSpinner'

const useCountdown = () => {
    const [counter, setCounter] = useState(0)

    useEffect(() => {
        if (counter === 0) return

        const timeout = setTimeout(() => {
            setCounter(Math.max(counter - 1, 0))
        }, 1000)

        return () => {
            clearTimeout(timeout)
        }
    }, [counter])

    const start = setCounter

    return {counter, start}
}

const ConfirmationModal = ({show, onHide, onConfirm, children, title, confirmationDelay}) => {
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const {counter, start} = useCountdown()

    useEffect(() => {
        if (show) {
            if (confirmationDelay) {
                start(Math.floor(confirmationDelay / 1000))
            }
        } else {
            setError('')
        }
    }, [show, confirmationDelay, start])

    const handleClick = () => {
        setIsLoading(true)
        Promise.resolve(onConfirm())
            .then(onHide)
            .catch(setError)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleHide = () => {
        setError('')
        onHide()
    }

    return (
        <Modal show={show} onHide={handleHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {children}
                <Alert
                    //dismissible
                    variant='danger'
                    show={!!error}>
                    {error}
                </Alert>
            </Modal.Body>

            <Modal.Footer>
                <Button variant='danger' onClick={handleHide}>
                    Cancel
                </Button>
                <ForwardKeysToButtonClick keys={['Enter', 'Space']}>
                    <Button variant='success' disabled={isLoading || counter > 0} onClick={handleClick}>
                        {isLoading && <LoadingSpinner />} {counter > 0 ? counter : 'Save Changes'}
                    </Button>
                </ForwardKeysToButtonClick>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal
