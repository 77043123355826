import {useUser} from '../../../../context/UserContext'
import {CustomTable} from '../../../Common/CustomTable/CustomTable'
import './GeneralInformation.css'

const TextCell = ({children}) => <span className='general-information-table-cell'>{children}</span>

const GeneralInformation = () => {
	const user = useUser()

	const data = {
		Username: user.details.username,
		Email: user.details.email,
		'User ID': user.details.id,
	}
	if (user.ip) data['Current IP'] = user.ip

	const entriesRule = {
		cellWidget: (id) => <TextCell>{id}</TextCell>,
	}

	const valuesRule = {
		cellWidget: (id) => <TextCell>{data[id]}</TextCell>,
	}

	return <CustomTable data={Object.keys(data)} rules={[entriesRule, valuesRule]} />
}

export default GeneralInformation
