import React, {useCallback, useEffect, useState} from 'react'
import MachineService from '../../../../services/MachineService'
import {useCookedStatisticsDispatch} from '../../../../context/CookedStatisticsContext'
import {useStatisticsLoader} from '../../../../context/StatisticsContext'
import DropdownMultiselect from '../../../Common/DropdownMultiselect'
import moment from 'moment'
import {ContentLoader} from '../../../Common/ContentLoader/ContentLoader'
import {Button} from 'react-bootstrap'
import {DownloadStatisticsButton} from './DownloadStatisticsButton'
import './StatisticsOptions.css'
import {useIsAdmin} from '../../../../context/UserContext'

function enumerateMachines() {
	return Object.values(MachineService.MachineType)
}

function cook(stats, locationNames, machineNames, fromDate, toDate) {
	const isValidMachineCode = (machineCode) => machineNames.includes(MachineService.machineCodeToName(machineCode))

	const fromTs = moment(fromDate).valueOf()
	const toTs = moment(toDate).valueOf()
	const isInRange = (ts) => fromTs <= ts && ts <= toTs

	return stats.rawData
		.filter((data) => locationNames.includes(data.locationName))
		.map((data) => ({
			locationName: data.locationName,
			machineEvents: data.machineEvents.filter(
				(event) => isValidMachineCode(event.machineCode) && isInRange(event.timeStarted)
			),
			cloverEvents: data.cloverEvents.filter((event) => isInRange(event.createdTime)),
		}))
}

const DATE_FORMAT = 'YYYY-MM-DD'

function yyyymmdd(date) {
	return moment(date).local(true).format('YYYY-MM-DD')
}

function clampDate(min, value, max) {
	return value < min ? min : value > max ? max : value
}

const StatisticsOptions = () => {
	const statsLoader = useStatisticsLoader()

	const machineNamesOptions = enumerateMachines()
	const [locationNamesOptions, setLocationNamesOptions] = useState(null)

	const [earliestDate, setEarliestDate] = useState(null)

	const [selectedMachineNames, setSelectedMachineNames] = useState(machineNamesOptions)
	const [selectedLocationNames, setSelectedLocationNames] = useState(null)

	const now = new Date()

	const [startDate, setStartDate] = useState(moment(now).startOf('day').toDate())
	const [endDate, setEndDate] = useState(moment(now).endOf('day').toDate())

	const [isLoading, setIsLoading] = useState(true)
	const [isProcessing, setIsProcessing] = useState(false)

	const dispatchCooked = useCookedStatisticsDispatch()

	const cookAndDispatch = useCallback(
		(data) => {
			new Promise((res) => {
				const cooked = cook(data, selectedLocationNames, selectedMachineNames, startDate, endDate)
				res(cooked)
			}).then(dispatchCooked)
		},
		// missing dependency: 'dispatchCooked'.
		// eslint-disable-next-line
		[selectedLocationNames, selectedMachineNames, startDate, endDate]
	)

	const handleApplyClick = () => {
		setIsProcessing(true)
	}

	useEffect(() => {
		statsLoader().then((data) => {
			if (!data) return
			const locationNames = data.rawData.map((entry) => entry.locationName)
			setLocationNamesOptions(locationNames)
			setEarliestDate(new Date(data.service.canSearchFrom))
			dispatchCooked(cook(data, locationNames, machineNamesOptions, startDate, endDate))
		})

		// missing dependency: ...
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (!locationNamesOptions || !earliestDate) return
		setSelectedLocationNames(locationNamesOptions)
		setIsLoading(false)
	}, [locationNamesOptions, earliestDate])

	useEffect(() => {
		if (!isProcessing) return
		let isMounted = true

		statsLoader(startDate, endDate).then((data) => {
			if (!data) {
				return
			}

			if (!isMounted) {
				return
			}

			setIsProcessing(false)
			cookAndDispatch(data)
		})

		return () => {
			isMounted = false
		}

		// missing dependency: 'cookAndDispatch', 'endDate', 'startDate', and 'statsLoader'. Either include them or remove the dependency array
		// eslint-disable-next-line
	}, [isProcessing])

	const canSubmit = !isLoading && !isProcessing && selectedLocationNames?.length > 0 && selectedMachineNames.length > 0
	const isAdmin = useIsAdmin()
	return (
		<div className='stats-options-container'>
			<ContentLoader loading={isLoading} type='grow'>
				<div className='stats-options-controls-container'>
					<DropdownMultiselect
						title='Machines'
						options={machineNamesOptions.map((item) => ({value: item, label: item}))}
						defaultValues={machineNamesOptions}
						onSelect={(selected) => {
							setSelectedMachineNames(selected)
						}}
					/>

					<DropdownMultiselect
						title='Locations'
						options={locationNamesOptions?.map((item) => ({value: item, label: item}))}
						defaultValues={locationNamesOptions}
						onSelect={(selected) => {
							setSelectedLocationNames(selected)
						}}
					/>
				</div>

				<div className='stats-options-controls-container'>
					<div>
						<div className='stats-option-date-picker-description'>From:</div>
						<input
							className='stats-option-date-picker'
							type='date'
							min={yyyymmdd(earliestDate)}
							max={yyyymmdd(endDate)}
							value={yyyymmdd(startDate)}
							onChange={(e) => {
								const input = moment(e.target.value, DATE_FORMAT).toDate()
								setStartDate(clampDate(earliestDate, input, endDate))
							}}
						/>
					</div>

					<div>
						<div className='stats-option-date-picker-description'>To:</div>
						<input
							className='stats-option-date-picker'
							type='date'
							min={yyyymmdd(startDate)}
							max={yyyymmdd(new Date())}
							value={yyyymmdd(endDate)}
							onChange={(e) => {
								const input = moment(e.target.value, DATE_FORMAT).endOf('day').toDate()
								setEndDate(clampDate(startDate, input, now))
							}}
						/>
					</div>
				</div>

				<div className='stats-options-controls-container'>
					{isAdmin && <DownloadStatisticsButton />}
					<Button variant='primary' className='btn-green' onClick={handleApplyClick} disabled={!canSubmit}>
						{isProcessing && <span className='spinner-border spinner-border-sm' />}
						Apply
					</Button>
				</div>
			</ContentLoader>
		</div>
	)
}

export {StatisticsOptions}
