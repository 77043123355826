import {useAdminData} from '../../context/AdminDataContext'
import {ContentLoader} from '../Common/ContentLoader/ContentLoader'
import {NoContentMessage} from '../Common/NoContentMessage/NoContentMessage'
import AdminTabs from './Utils/AdminTabs'

import EditLocations from './EditLocations/EditLocations'
import {GameView} from './GamesList/GameView'
import {LocationsAndUsers} from './LocationsAndUsers/LocationsAndUsers'
import {MoveMachinesToLocations} from './MachinesAndLocations/MachinesAndLocations'
import LatestVersionPage from './SoftwareTab/Software'
import EditUsers from './Users/EditUsers'

const AdminDashboard = () => {
    const [db, isLoading] = useAdminData()

    return (
        <ContentLoader loading={isLoading}>
            {db ? (
                <AdminTabs
                    tabs={[
                        {
                            name: 'Machines & Locations',
                            component: MoveMachinesToLocations,
                            id: 'machines',
                        },
                        {
                            name: 'Locations & Users',
                            component: LocationsAndUsers,
                            id: 'locations',
                        },
                        {
                            name: 'Edit Locations',
                            component: EditLocations,
                            id: 'edit_locations',
                        },
                        {
                            name: 'Edit Users',
                            component: EditUsers,
                            id: 'users',
                        },
                        {
                            name: 'Software',
                            component: LatestVersionPage,
                            id: 'software',
                        },
                        {
                            name: 'View Games',
                            component: GameView,
                            id: 'games',
                        },
                    ]}
                />
            ) : (
                <NoContentMessage text='No data available' />
            )}
        </ContentLoader>
    )
}

export default AdminDashboard
