import './App.css'
import {BrowserRouter} from 'react-router-dom'

import Login from './components/Authentication/Login'
import Register from './components/Authentication/Register'
import Restore from './components/Authentication/Restore'
import ChangePassword from './components/Authentication/ChangePassword'
import UserDashboard from './components/User/UserDashboard/UserDashboard'
import AdminDashboard from './components/AdminDashboard/AdminDashboard'

import {NotFound, Support, Offline, Terms, Privacy} from './components/ServicePages/ServicePages'

import UserStatistics from './components/User/UserStatistics/UserStatistics'
import UserProfile from './components/User/UserProfile/UserProfile'

import {UserProvider} from './context/UserContext'
import UpdatesProvider from './UpdatesProvider'

import {RouteResolver} from './RouteResolver'

import UserService from './services/UserService'
import OTP from './components/Common/OTPpage/OTP'

const routes = [
	{
		path: '/login',
		component: Login,
		accessList: [UserService.roles.guest],
		title: 'Login Page',
		// showFooter: true,
	},
	{
		path: '/register',
		component: Register,
		accessList: [UserService.roles.guest],
		title: 'Register Page',
		// showFooter: true,
	},
	{
		path: '/restore',
		component: Restore,
		accessList: [UserService.roles.guest],
		title: 'Restore password',
		// showFooter: true,
	},
	{
		path: '/changePassword',
		component: ChangePassword,
		accessList: [UserService.roles.guest, UserService.roles.user, UserService.roles.vip, UserService.roles.admin],
		title: 'Change password',
		// showFooter: true,
	},
	{
		path: '/admin',
		component: AdminDashboard,
		accessList: [UserService.roles.admin],
		title: 'Admin Page',
		isNavigable: true,
		showHeader: true,
		showFooter: true,
	},
	{
		path: '/dashboard',
		component: UserDashboard,
		accessList: [UserService.roles.user, UserService.roles.vip, UserService.roles.admin],
		title: 'Dashboard',
		isNavigable: true,
		showHeader: true,
		showFooter: true,
	},
	{
		path: '/stats',
		component: UserStatistics,
		accessList: [UserService.roles.user, UserService.roles.vip, UserService.roles.admin],
		title: 'Statistics',
		isNavigable: true,
		showHeader: true,
		showFooter: true,
	},
	{
		path: '/profile',
		component: UserProfile,
		accessList: [UserService.roles.user, UserService.roles.vip, UserService.roles.admin],
		title: 'Profile',
		isNavigable: true,
		showHeader: true,
		showFooter: true,
	},
	{
		path: '/support',
		component: Support,
		accessList: [UserService.roles.guest, UserService.roles.user, UserService.roles.vip, UserService.roles.admin],
		title: 'Support',
	},
	{
		path: '/terms', //cannot be an array anymore after upgrading react router
		component: Terms,
		accessList: [UserService.roles.guest, UserService.roles.user, UserService.roles.vip, UserService.roles.admin],
		title: 'Terms of Use',
	},
	{
		path: '/privacy',
		component: Privacy,
		accessList: [UserService.roles.guest, UserService.roles.user, UserService.roles.vip, UserService.roles.admin],
		title: 'Privacy Policy',
	},
	{
		path: '/offline',
		component: Offline,
		accessList: [UserService.roles.guest, UserService.roles.user, UserService.roles.vip, UserService.roles.admin],
		title: 'Offline',
	},
	{
		path: '/otp',
		component: OTP,
		accessList: [UserService.roles.guest, UserService.roles.user, UserService.roles.vip, UserService.roles.admin],
		title: 'Get your OTP app!',
	},
	{
		path: '/*',
		component: NotFound,
		accessList: [UserService.roles.guest, UserService.roles.user, UserService.roles.vip, UserService.roles.admin],
		title: 'Page not Found',
	},
]

const defaultRoutes = {
	guest: '/login',
	user: '/dashboard',
	vip: '/dashboard',
	admin: '/admin',
}

function App() {
	return (
		<BrowserRouter>
			<UserProvider>
				<UpdatesProvider>
					<RouteResolver routes={routes} defaults={defaultRoutes} />
				</UpdatesProvider>
			</UserProvider>
		</BrowserRouter>
	)
}

export default App
