import {Link} from 'react-router-dom'

const Footer = () => (
	<footer className='footer'>
		<Link to='/support'>Support</Link>

		<Link to='/terms'>Terms of Use</Link>

		<Link to='/privacy'>Privacy Policy</Link>
	</footer>
)

export default Footer
