import {useNavigate} from 'react-router-dom'
import './ServicePage.css'

const ServicePageTemplate = ({title, children}) => {
	const navigate = useNavigate()
	const handleGoBack = (e) => {
		e.preventDefault()
		navigate('/')
		// history.goBack()
	}

	return (
		<div className='service-page'>
			<div className='service-page-header'>{title}</div>

			<div className='service-page-link-container'>{children}</div>

			<div className='service-page-link-container service-page-go-back'>
				<a href='/' onClick={handleGoBack}>
					← Go Back
				</a>
			</div>
		</div>
	)
}

export default ServicePageTemplate
