import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import MachineService from '../../../services/MachineService'
import {useAdminData} from '../../../context/AdminDataContext'
import {timeSincePrettyString} from '../../../utils/timeUtils'
import React from 'react'
import './MachineView.css'

const MachineWidget = ({
	machineCode = '',
	activeUntil = '',
	createdAt = '',
	uuid = '',
	lastKnownIp = '',
	swVersion = '',
}) => {
	const renderMachineTooltip = (props) => (
		<Tooltip {...props}>
			<img src={MachineService.machineCodeToImage(machineCode)} alt={machineCode} className='tooltip-machine-image' />
		</Tooltip>
	)

	return (
		<OverlayTrigger placement='auto' overlay={renderMachineTooltip}>
			{({ref, ...triggerHandler}) => (
				<div {...triggerHandler} ref={ref}>
					{`🆔${machineCode}`}
					{uuid && ` => ${uuid}`}
					<br />
					{`🕰 Activity: ${createdAt}`}
					{activeUntil && ` => ${activeUntil}`}
					<br />
					{`🖥 IP: ${lastKnownIp}`}
					<br />
					{`SW Version: ${swVersion}`}
				</div>
			)}
		</OverlayTrigger>
	)
}

const MachineView = ({id}) => {
	const [db] = useAdminData()
	const machine = db.machines[id]
	const activeUntil = timeSincePrettyString(machine.activeUntil)
	const createdAt = timeSincePrettyString(machine.createdAt)

	return (
		<MachineWidget
			machineCode={machine.machineCode || 'unknown'}
			activeUntil={activeUntil}
			createdAt={createdAt || 'unknown'}
			uuid={id}
			lastKnownIp={machine.lastKnownIp || 'unknown'}
			swVersion={machine.swVersion || 'unknown'}
		/>
	)
}

export {MachineView}
