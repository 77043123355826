import {PencilIcon, PlusCircleIcon, StopIcon, XCircleIcon} from '@primer/octicons-react'
import React, {useState} from 'react'
import {Button} from 'react-bootstrap'

import DataService from '../../../services/DataService'

import {useAdminData} from '../../../context/AdminDataContext'
import {CustomTable} from '../../Common/CustomTable/CustomTable'
import ConfirmationModal from '../Utils/ConfirmationModal'
import {useLocationsDictionary} from '../hooks/useLocationsDictionary'

import CreateOrEditLocationModal from './CreateOrEditLocationModal'


const DeleteLocationModal = ({show, onHide, onSubmit}) => (
	<ConfirmationModal show={show} onHide={onHide} onConfirm={onSubmit} title={'Confirm removing'} />
)

const UnbindLocationModal = ({show, onHide, onSubmit}) => (
	<ConfirmationModal show={show} onHide={onHide} onConfirm={onSubmit} title={'Confirm unbind'} />
)

const EditLocations = () => {
	const [db] = useAdminData()
	const [isShowing, setShow] = useState(false)
	const [modal, setModal] = useState({
		component: CreateOrEditLocationModal,
		onSubmit: () => {},
	})

	const handleShow = () => {
		setShow(true)
	}

	const handleClose = () => {
		setShow(false)
	}

	const handleAdd = async ({name, address}) => {
		await DataService.newLocation({name, address})
	}

	const handleEdit = async ({locationId, name, address}) => {
		await DataService.editLocation({locationId, name, address})
	}

	const handleDelete = async ({locationId}) => {
		await DataService.removeLocation({locationId})
	}
	const handleUnbind = async ({locationId}) => {
		await DataService.unbindCloverData({locationId})
	}

	const LocationAction = {
		add: () => {
			setModal({
				component: CreateOrEditLocationModal,
				onSubmit: handleAdd,
			})
			handleShow()
		},
		edit: (locationId) => {
			setModal({
				component: CreateOrEditLocationModal,
				onSubmit: (args) => handleEdit({locationId, ...args}),
			})
			handleShow()
		},
		remove: (locationId) => {
			setModal({
				component: DeleteLocationModal,
				onSubmit: () => handleDelete({locationId}),
			})
			handleShow()
		},
		unbind: (locationId) => {
			setModal({
				component: UnbindLocationModal,
				onSubmit: () => handleUnbind({locationId}),
			})
			handleShow()
		},
	}

	const locationPrettyNames = useLocationsDictionary()

	const locationRule = {
		header: 'Locations',
		sorters: [
			{
				name: 'By time created',
				sort: (l, r) => Date.parse(db.locations[l].createdAt) - Date.parse(db.locations[r].createdAt),
			},
			{
				name: 'By name',
				sort: (l, r) => locationPrettyNames[l].localeCompare(locationPrettyNames[r]),
			},
			{
				name: 'By amount of machines',
				sort: (l, r) => db.locations[r].machines.length - db.locations[l].machines.length,
			},
		],
		test: (enteredText, id) => locationPrettyNames[id].toLowerCase().includes(enteredText.toLowerCase()),
		cellWidget: (location) => (
			<>
				{locationPrettyNames[location]}
				<br />
				Has {db.locations[location].machines.length} machines
			</>
		),
	}

	const actionRule = {
		header: 'Actions',
		cellWidget: (location) => (
			<>
				<Button
					variant='light'
					onClick={() => {
						LocationAction.edit(location)
					}}>
					<PencilIcon size={16} verticalAlign='middle' /> Edit
				</Button>

				<Button
					variant='danger'
					onClick={() => {
						LocationAction.remove(location)
					}}>
					<XCircleIcon size={16} verticalAlign='middle' /> Delete
				</Button>

				<Button
					variant='info'
					disabled={!db.locations[location].cloverMerchant}
					onClick={() => {
						LocationAction.unbind(location)
					}}>
					<StopIcon size={16} verticalAlign='middle' /> Unbind
				</Button>
			</>
		),
		filters: [
			{
				name: 'Show all',
				filter: () => true,
			},
			{
				name: 'Clover binded',
				filter: (location) => !!db.locations[location].cloverMerchant,
			},
			{
				name: 'Clover not binded',
				filter: (location) => !db.locations[location].cloverMerchant,
			},
		],
	}

	return (
		<>
			<CustomTable
				data={Object.keys(db.locations)}
				rules={[locationRule, actionRule]}
				specialRows={() => (
					<tr key='Add location'>
						<td>New location</td>
						<td>
							<Button variant='primary' onClick={LocationAction.add} className='btn-green'>
								<PlusCircleIcon size={16} verticalAlign='middle' /> Create
							</Button>
						</td>
					</tr>
				)}
			/>

			<modal.component show={isShowing} onHide={handleClose} onSubmit={modal.onSubmit} />
		</>
	)
}

export default EditLocations
