import React, {useEffect, useState} from 'react'
import {Nav, Navbar, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {NavLink, useNavigate} from 'react-router-dom'
import {useSocket} from '../../context/SocketContext'
import {useIsAdmin} from '../../context/UserContext'
// import logo from '../../../build/images/logo_x2.svg'
// eslint-disable-next-line
import {useLogout, UserRole} from '../../context/UserContext'
import {useNavigation} from '../../RouteResolver'
import {CircleIndicator} from './CircleIndicator/CircleIndicator'

const SocketBadStateIndicator = () => {
    const socket = useSocket()
    const [show, setShow] = useState(false)
    const handleShow = () => {
        setShow(true)
    }
    const handleHide = () => {
        setShow(false)
    }

    useEffect(() => {
        if (socket) {
            handleHide()
            return
        }

        const timeout = setTimeout(handleShow, 3000)
        return () => {
            clearTimeout(timeout)
        }
    }, [socket])

    const webhookTooltip = (props) => (
        <Tooltip {...props}>
            Webhook is disconnected, please refresh the page. (if this continues to happen, please talk to Meir)
        </Tooltip>
    )

    if (show) {
        return (
            <OverlayTrigger placement='bottom' overlay={webhookTooltip}>
                <CircleIndicator active={socket} />
            </OverlayTrigger>
        )
    }

    return <></>
}

const Header = () => {
	const logout = useLogout()

	const pages = useNavigation()
	const navigate = useNavigate()
	const isAdmin = useIsAdmin()

	// const ref = React.createRef()

	return (
		<Navbar collapseOnSelect expand='md' className='navbar-dark align-items-center '>
			<Navbar.Brand
				href={'/'}
				onClick={(e) => {
					e.preventDefault()
					if (isAdmin) {
						const url = window.location.href
						const subDir = url.substring(url.lastIndexOf('/'))

						if (subDir === '/admin') {
							navigate('/dashboard')
							return
						}
					}

					navigate('/')
				}}>
				<img src={window.location.origin + '/images/logo_x2.svg'} width='144' height='72' alt='logo' />
			</Navbar.Brand>

			<SocketBadStateIndicator />

			<Navbar.Toggle aria-controls='responsive-navbar-nav' />

			<Navbar.Collapse id='responsive-navbar-nav'>
				<Nav>
					{pages &&
						pages.length > 1 &&
						pages.map((page) => (
							<Nav.Link as={NavLink} key={page.path} to={page.path}>
								{page.title}
							</Nav.Link>
						))}
				</Nav>
				<Nav className='mr-auto' />

				<Nav>
					<Nav.Link
						onClick={() => {
							logout()
						}}>
						Logout
					</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}

export default Header
