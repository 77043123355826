import React, {useState} from 'react'
import {Button} from 'react-bootstrap'
import {DownloadIcon} from '@primer/octicons-react'
import DataService from '../../../../services/DataService'
import {useIsAdmin} from '../../../../context/UserContext'
import {useAsync} from '../../../../utils/useAsync'
import LoadingSpinner from '../../../Common/LoadingSpinner'

function downloadFile(data) {
	const file = new Blob([data], {type: 'text/plain'})
	const a = document.createElement('a')
	const url = URL.createObjectURL(file)
	a.href = url
	a.download = 'data.csv'
	document.body.appendChild(a)
	a.click()

	setTimeout(function () {
		document.body.removeChild(a)
		window.URL.revokeObjectURL(url)
	}, 0)
}

const DownloadStatisticsButton = (props) => {
	const isAdmin = useIsAdmin()
	const [isLoading, setIsLoading] = useState(false)

	const asyncFn = useAsync()

	const handleDownload = () => {
		setIsLoading(true)

		asyncFn(DataService.downloadStats()).then(
			(data) => {
				downloadFile(data)
				setIsLoading(false)
			},
			(error) => console.log(error)
		)
	}

	return (
		<Button {...props} onClick={handleDownload} disabled={!isAdmin || isLoading}>
			{isLoading ? (
				<>
					<LoadingSpinner />
					Loading
				</>
			) : (
				<>
					<DownloadIcon size={16} verticalAlign='middle' />
					Download
				</>
			)}
		</Button>
	)
}

export {DownloadStatisticsButton}
