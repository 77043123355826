import LocationView from './LocationView'
import {useUserData} from '../../../context/UserDataContext'
import {ContentLoader} from '../../Common/ContentLoader/ContentLoader'
import './UserDashboard.css'
import {NoContentMessage} from '../../Common/NoContentMessage/NoContentMessage'

const isNonEmptyObject = (obj) => obj && Object.keys(obj).length > 0

const UserDashboard = () => {
	const [userData, isDataLoading] = useUserData()

	const Locations = () => {
		const locationIds = Object.keys(userData.locations || {})
		return (
			<div className='flex-box-locations'>
				{locationIds.map((id) => (
					<LocationView key={id} id={id} />
				))}
			</div>
		)
	}

	return (
		<ContentLoader loading={isDataLoading}>
			{userData && isNonEmptyObject(userData.locations) ? <Locations /> : <NoContentMessage text='No locations found' />}
		</ContentLoader>
	)
}

export default UserDashboard
