import React, {useEffect, useState} from 'react'
import DataService from '../../../services/DataService'

const LatestVersionPage = () => {
    const [latestVersion, setLatestVersion] = useState({})
    const [isUpdateSectionOpen, setIsUpdateSectionOpen] = useState(false)
    const [processingTime, setProcessingTime] = useState(0)
    const [message, setmessage] = useState(false)
    // for form
    const [versionNumber, setVersionNumber] = useState('')
    const [downloadLink, setDownloadLink] = useState('')
    const [releaseDate, setReleaseDate] = useState(Date.now)
    const [releaseNotes, setReleaseNotes] = useState('')

    useEffect(() => {
        const fetchLatestVersion = async () => {
            const lts = await DataService.getLatestSoftware({uuid: 'Frontend', version: 0.1, versionTimestamp: 10})
            console.log(lts)
            setLatestVersion(lts)
            setProcessingTime(lts.processingTime)
            setmessage(lts.message)
        }

        fetchLatestVersion()
    }, []) // Fetch the latest version on component mount

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const response = await DataService.setLatestSoftware({
                versionNumber,
                downloadLink,
                releaseDate,
                releaseNotes,
            })
            setLatestVersion(response.updatedSoftware)
        } catch (error) {
            console.error('Error updating latest version:', error.response)
        }
    }

    return (
        <div>
            <h1>Latest Version Information</h1>
            <span>
                Latest version:
                <a href='https://vip.vr360action.com/software/getLatest'>
                    https://vip.vr360action.com/software/getLatest
                </a>
            </span>
            <br />
            <span>
                GitHub main page:
                <a rel='noopener noreferrer' href='https://github.com/jakevr360action/VR360ActionSoftware'>
                    GitHub release repo
                </a>
            </span>
            <br />
            <span>
                URL Shortner (T.LY):
                <a rel='noopener noreferrer' href='https://t.ly'>
                    Go to T.LY
                </a>
            </span>
            <br />
            <span>
                WebView 2 offline installer (direct link):
                <a
                    rel='noopener noreferrer'
                    href='https://msedge.sf.dl.delivery.mp.microsoft.com/filestreamingservice/files/9a6e8dca-0387-43a3-9ecc-3b48b82c1386/MicrosoftEdgeWebView2RuntimeInstallerX64.exe'>
                    Right click and copy download link
                </a>
            </span>
            <br />
            <span>
                YSRV (Google Drive):
                <a
                    rel='noopener noreferrer'
                    href='https://drive.google.com/drive/u/1/folders/17PwGenHp1c-uPs2u4ELjd7fzoVnJtxhk'>
                    open this link and find the latest version
                </a>
            </span>
            <br />
            <span>
                WebView 2 offline installer (official portal, if above doesnt work):
                <a
                    rel='noopener noreferrer'
                    href='https://developer.microsoft.com/en-us/microsoft-edge/webview2/?form=MA13LH#download'>
                    Right click and copy link
                </a>
            </span>
            {Object.keys(latestVersion).length > 0 ? (
                <div>
                    <p>Version Number: {latestVersion.versionNumber}</p>
                    <p>Download Link: {latestVersion.downloadLink}</p>
                    <p>Release Date: {latestVersion.releaseDate}</p>
                    <p>Release Notes: {latestVersion.releaseNotes}</p>
                    <p>ID: {latestVersion.id}</p>
                    <p>Processing Time: {processingTime}</p>
                    <p>Message: {message}</p>
                    {/* Add more information as needed */}
                </div>
            ) : (
                <p>No latest version information available.</p>
            )}

            <button onClick={() => setIsUpdateSectionOpen(!isUpdateSectionOpen)}>
                {isUpdateSectionOpen ? 'Collapse Update Section' : 'Expand Update Section'}
            </button>

            {isUpdateSectionOpen && (
                <div>
                    <h1>Update Latest Version</h1>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Version Number:
                            <input
                                type='text'
                                value={versionNumber}
                                onChange={(e) => setVersionNumber(e.target.value)}
                            />
                        </label>
                        <br />
                        <label>
                            Download Link:
                            <input type='text' value={downloadLink} onChange={(e) => setDownloadLink(e.target.value)} />
                        </label>
                        <br />
                        <label>
                            Release Date:
                            <input type='text' value={releaseDate} onChange={(e) => setReleaseDate(e.target.value)} />
                        </label>
                        <br />
                        <label>
                            Release Notes:
                            <input type='text' value={releaseNotes} onChange={(e) => setReleaseNotes(e.target.value)} />
                        </label>
                        <br />
                        <button type='submit'>Update Latest Version</button>
                    </form>
                </div>
            )}
        </div>
    )
}

export default LatestVersionPage
