import './OTP.css';
import OTPAppButtons from './OTPAppButtons';

const Main = () => {
    return (
        <div className="main-container">
            <h1>Choose your OTP App</h1>
            <p>Download and install your preferred OTP app:</p>
            <div className="otp-buttons-container">
                <OTPAppButtons />
            </div>
        </div>
    );
};

export default Main;
