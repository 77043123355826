import React, {useMemo, useState} from 'react'

import {XCircleIcon} from '@primer/octicons-react'
import {Button, Dropdown, DropdownButton, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useAdminData} from '../../../context/AdminDataContext'
import DataService from '../../../services/DataService'

import UserService from '../../../services/UserService'
import {timeSincePrettyString} from '../../../utils/timeUtils'
import {CustomTable} from '../../Common/CustomTable/CustomTable'
import ConfirmationModal from '../Utils/ConfirmationModal'

const roles = Object.keys(UserService.roles).filter((role) => role !== UserService.roles.guest)
const prettyRoles = roles.map((str) => {
    str = str.replaceAll('_', ' ')
    return str.charAt(0).toUpperCase() + str.slice(1)
})
const roleToIndex = Object.fromEntries(roles.map((role, index) => [role, index]))

const makePrettyRole = (role) => prettyRoles[roleToIndex[role]]

const RoleSelector = ({current, onChange}) => (
    <DropdownButton
        variant='transparent'
        title={makePrettyRole(current)}
        onSelect={(roleIndex) => {
            const role = roles[roleIndex]
            if (current !== role) onChange?.(role)
        }}>
        {roles.map((role, index) => (
            <Dropdown.Item key={index} active={role === current} eventKey={index}>
                {prettyRoles[index]}
            </Dropdown.Item>
        ))}
    </DropdownButton>
)

const EditUserRole = ({userId, userName, role}) => {
    const [showModal, setShowModal] = useState(false)
    const [newRole, setNewRole] = useState('')

    const handleShow = () => {
        setShowModal(true)
    }

    const handleHide = () => {
        setShowModal(false)
    }

    const handleSelectNewRole = (role) => {
        setNewRole(role)
        handleShow()
    }

    const handleRoleChange = async () => {
        await DataService.changeUserRole({userId, role: newRole})
    }

    return (
        <>
            <RoleSelector current={role} onChange={handleSelectNewRole} />
            <ConfirmationModal
                show={showModal}
                onHide={handleHide}
                title={`Change ${userName} role to ${makePrettyRole(newRole)}?`}
                onConfirm={handleRoleChange}
            />
        </>
    )
}

const RemoveUser = ({userId, userName}) => {
    const [showModal, setShowModal] = useState(false)

    const handleShow = () => {
        setShowModal(true)
    }

    const handleHide = () => {
        setShowModal(false)
    }

    const handleRemove = async () => {
        await DataService.deleteUser({userId})
    }

    return (
        <>
            <Button variant='danger' onClick={handleShow}>
                <XCircleIcon size={16} verticalAlign='middle' /> Delete
            </Button>
            <ConfirmationModal
                show={showModal}
                onHide={handleHide}
                title={`Remove ${userName}?`}
                onConfirm={handleRemove}
                confirmationDelay={3000}
            />
        </>
    )
}

const UserSessionInfo = ({token}) => {
    const {browser, platform, os, version} = token.userAgent
    const osPlatform = os === 'Linux' && platform === 'Android' ? platform : os || 'unknown os'
    const status = `${browser || 'Unknown browser'} on ${osPlatform} ${version || ''}`

    return (
        <div>
            {status}
            <br />
            {token.createdByIp && `IP: ${token.createdByIp}`}
        </div>
    )
}

const UserWidget = ({locationsNum, refreshTokens = [], createdAt, updatedAt, username, email}) => {
    const uniqueTokens = useMemo(() => {
        const uniqueIps = new Set(refreshTokens.map((token) => token.createdByIp))
        return refreshTokens.filter((token) => uniqueIps.delete(token.createdByIp))
    }, [refreshTokens])

    const userTooltip = (props) => (
        <Tooltip {...props}>
            {uniqueTokens.map((token) => (
                <UserSessionInfo token={token} />
            ))}
        </Tooltip>
    )
    const emailTooltip = (props) => (
        <Tooltip {...props}>
            <>{email}</>
        </Tooltip>
    )

    const userData = (
        <>
            {
                <OverlayTrigger placement='auto' overlay={emailTooltip}>
                    {({ref, ...triggerHandler}) => (
                        <div {...triggerHandler} ref={ref}>
                            {`💬${username}`}
                        </div>
                    )}
                </OverlayTrigger>
            }
            {`📍Has ${locationsNum || 0} locations`}
            <br />
            {uniqueTokens.length > 0 && (
                <OverlayTrigger placement='auto' overlay={userTooltip}>
                    {({ref, ...triggerHandler}) => (
                        <div {...triggerHandler} ref={ref}>
                            {`🥋Has ${uniqueTokens.length} active sessions`}
                        </div>
                    )}
                </OverlayTrigger>
            )}
            {createdAt && `🕰Activity: ${createdAt}`}
            {updatedAt && ` => ${updatedAt}`}
        </>
    )

    return userData
}

const EditUsers = () => {
    const [db] = useAdminData()
    const users = db.users

    const usersRule = {
        header: 'Users',
        accessor: 'users',
        cellWidget: (user) => {
            return (
                <UserWidget
                    locationsNum={users[user].locations.length}
                    refreshTokens={users[user].refreshTokens}
                    createdAt={timeSincePrettyString(users[user].createdAt)}
                    updatedAt={timeSincePrettyString(users[user].updatedAt)}
                    username={users[user].username}
                    email={users[user].email}
                />
            )
        },
        sorters: [
            {
                name: 'By name',
                sort: (l, r) => users[l].username.localeCompare(users[r].username),
            },
            {
                name: 'By location amount ↓',
                sort: (l, r) => users[l].locations.length - users[r].locations.length,
            },
            {
                name: 'By location amount ↑',
                sort: (l, r) => users[r].locations.length - users[l].locations.length,
            },
            {
                name: 'By active sessions ↓',
                sort: (l, r) => users[l].refreshTokens.length - users[r].refreshTokens.length,
            },
            {
                name: 'By active sessions ↑',
                sort: (l, r) => users[r].refreshTokens.length - users[l].refreshTokens.length,
            },
            {
                name: 'By registration date ↓',
                sort: (l, r) => Date.parse(users[l].createdAt) - Date.parse(users[r].createdAt),
            },
            {
                name: 'By registration date ↑',
                sort: (l, r) => Date.parse(users[r].createdAt) - Date.parse(users[l].createdAt),
            },
            {
                name: 'By last updated ↓',
                sort: (l, r) => Date.parse(users[l].updatedAt) - Date.parse(users[r].updatedAt),
            },
            {
                name: 'By last updated ↑',
                sort: (l, r) => Date.parse(users[r].updatedAt) - Date.parse(users[l].updatedAt),
            },
        ],
        test: (enteredText, user) => users[user].username.toLowerCase().includes(enteredText.toLowerCase()),
    }

    const userRoleRule = {
        header: 'Roles',
        accessor: 'users',
        cellWidget: (user) => <EditUserRole userId={user} userName={users[user].username} role={users[user].role} />,
        filters: [
            {
                name: 'Show all',
                filter: () => true,
            },
            {
                name: 'Show Admin',
                filter: (user) => users[user].role === 'admin',
            },
            {
                name: 'Show VIP',
                filter: (user) => users[user].role === 'vip',
            },
            {
                name: 'Show User',
                filter: (user) => users[user].role === 'user',
            },
        ],
    }

    const userActionRule = {
        header: 'Actions',
        accessor: 'users',
        cellWidget: (user) => <RemoveUser userId={user} userName={users[user].username} />,
    }

    return <CustomTable data={Object.keys(users)} rules={[usersRule, userRoleRule, userActionRule]} />
}

export default EditUsers
