import moment from 'moment/moment'

const isToday = (date) => {
    if (typeof date === 'string') {
        date = new Date(date)
    }
    const today = new Date()
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    )
}

const unixMinute = 60 * 1000
const unixHour = 60 * unixMinute
const unixDay = 24 * unixHour
const unixWeek = 7 * unixDay
const unixMonth = 30 * unixDay
const unixYear = 365 * unixDay

// const errorMs = unixMinute

const timeUnits = [
    {unit: 'year', duration: unixYear},
    {unit: 'month', duration: unixMonth},
    {unit: 'week', duration: unixWeek},
    {unit: 'day', duration: unixDay},
    {unit: 'hour', duration: unixHour},
    {unit: 'minute', duration: unixMinute},
]

const timeSincePrettyString = (ts) => {
    const now = moment()
    let parsedDate

    if (typeof ts === 'string') {
        parsedDate = moment(ts, 'YYYY-MM-DD HH:mm:ss').utcOffset(0, true)
    } else if (typeof ts === 'number') {
        parsedDate = moment(ts).utcOffset(0, true)
    } else {
        const errorMessage = 'Invalid timestamp: ' + ts
        console.error('timeSincePrettyString', errorMessage)
        return errorMessage
    }

    if (!parsedDate.isValid()) {
        const errorMessage = 'Invalid date: ' + ts
        console.error('timeSincePrettyString', errorMessage)
        return errorMessage
    }

    const duration = moment.duration(now.diff(parsedDate))
    const past = duration.asMilliseconds()

    // let past = now - (utcTimestamp - localOffset)

    if (past <= 0) {
        const remaining = -past
        for (const {unit, duration: unitDuration} of timeUnits) {
            const value = Math.floor(remaining / unitDuration)
            if (value > 0) {
                return `${value} ${unit}${value === 1 ? '' : 's'} left`
            }
        }
        return 'now'
    } else if (past < unixMinute) {
        return 'within last minute'
    } else {
        for (const {unit, duration: unitDuration} of timeUnits) {
            const value = Math.round(past / unitDuration)
            if (value >= 1) {
                return `${value} ${unit}${value === 1 ? '' : 's'} ago`
            }
        }
        console.error('timeSincePrettyString returning a long time ago', past, ts)
        return 'a very long time ago'
    }
}

export {isToday, timeSincePrettyString}
