import React, {useCallback, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {DashIcon, PlusIcon} from '@primer/octicons-react'

const Toggle = React.forwardRef(({children, onClick}, ref) => (
	// eslint-disable-next-line jsx-a11y/anchor-is-valid
	<a
		href=''
		ref={ref}
		onClick={(e) => {
			e.preventDefault()
			onClick(e)
		}}>
		{children} &#x25bc;
	</a>
))

const DropdownMultiselect = ({title, options = [], defaultValues = [], isLoading, onSelect, onAdd, onRemove}) => {
	const [isShown, setIsShown] = useState(false)

	const [values, setValues] = useState(
		options.map((item) => ({
			label: item.label,
			value: item.value,
			isSelected: defaultValues.includes(item.value),
		}))
	)

	const onSelectHandler = useCallback(
		(eventKey) => {
			const newValues = [...values]
			const item = newValues[eventKey]
			item.isSelected = !item.isSelected
			setValues(newValues)

			if (item.isSelected) {
				onAdd?.(item.value)
			} else {
				onRemove?.(item.value)
			}

			const selected = values.filter((item) => item.isSelected).map((item) => item.value)

			onSelect?.(selected)
		},
		// eslint-disable-next-line
		[values, onSelect] //, onAdd, onRemove]
	)

	const onToggleHandler = (isOpen, event) => {
		if (event.source !== 'select') {
			setIsShown(isOpen)
		}

		if (event.originalEvent.type === 'click') {
			// Remove ugly colors
			event.originalEvent.target.blur()
		}
	}

	const isReady = !isLoading && !!options

	return (
		<Dropdown show={isShown} onToggle={onToggleHandler} onSelect={onSelectHandler}>
			<Dropdown.Toggle as={Toggle} disabled={!isReady}>
				{title}
			</Dropdown.Toggle>
			{isReady && (
				<Dropdown.Menu>
					{values.map((item, index) => (
						<Dropdown.Item active={values[index].isSelected} key={index} eventKey={index}>
							{values[index].isSelected ? (
								<PlusIcon size={16} verticalAlign={'middle'} />
							) : (
								<DashIcon size={16} verticalAlign={'middle'} />
							)}
							{values[index].label}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			)}
		</Dropdown>
	)
}

export default DropdownMultiselect
