import React, {useContext} from 'react'
import {Accordion, Button, useAccordionButton, AccordionContext} from 'react-bootstrap'
import {ArrowDownIcon} from '@primer/octicons-react'
import {CustomCard} from '../../../Common/CustomCard/CustomCard'
import './ProfileAccordion.css'

const AccordionButton = ({children, eventKey}) => {
	const decoratedOnClick = useAccordionButton(eventKey)
	const {activeEventKey} = useContext(AccordionContext)
	const showArrow = activeEventKey !== eventKey

	return (
		<Button variant='accordion' onClick={decoratedOnClick}>
			{children}
			{showArrow && <ArrowDownIcon size={16} verticalAlign='middle' />}
		</Button>
	)
}

const Shadowed = ({children}) => (
	<div style={{position: 'relative'}}>
		<div className='shadowed' />
		{children}
	</div>
)

const ProfileAccordion = ({entries, page}) => (
	<Accordion>
		<CustomCard.Item>
			{entries.map((entry, index) => (
				<React.Fragment key={index}>
					<CustomCard.Header key={index}>
						<AccordionButton eventKey={`${index}`} openOnLoad={index === page}>
							{entry.title}
						</AccordionButton>
					</CustomCard.Header>
					<Accordion.Collapse eventKey={`${index}`}>
						<Shadowed>
							<entry.component />
						</Shadowed>
					</Accordion.Collapse>
				</React.Fragment>
			))}
		</CustomCard.Item>
	</Accordion>
)

export default ProfileAccordion
