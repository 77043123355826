import {useAdminData} from '../../../context/AdminDataContext'
import {useMemo} from 'react'

const useLocationsUsers = () => {
	const [db] = useAdminData()

	return useMemo(() => {
		const locationUsers = {}
		Object.keys(db.locations).forEach((locationId) => {
			locationUsers[locationId] = []
		})
		Object.keys(db.users).forEach((userId) => {
			db.users[userId].locations.forEach((locationId) => {
				locationUsers[locationId]?.push(userId)
			})
		})
		return locationUsers
	}, [db])
}

export {useLocationsUsers}
