import {useMemo} from 'react'
import Chart from 'react-apexcharts'
import {useCookedStatistics} from '../../../../context/CookedStatisticsContext'

function calculatePlayedGames(stats) {
	/*
    {
        categories: ['Location 1', 'Location 2', 'Location 3'],
        values: [
            {
                name: 'Game 1',
                data: [10, 20, 30]
            },
            {
                name: "Game 2",
                data: [40, 50, 60]
            }
        ]
    }
    */

	const nonEmptyLocations = stats.filter((entry) => entry.machineEvents.length > 0)

	const playedGames = {
		categories: nonEmptyLocations.map((entry) => entry.locationName),
		values: [],
	}

	const locationsAmount = playedGames.categories.length

	const gameNameToIndex = {}
	const getOrCreateGameEntry = (gameName) => {
		if (gameNameToIndex[gameName] === undefined) {
			const index = Object.keys(gameNameToIndex).length
			gameNameToIndex[gameName] = index
			playedGames.values[index] = {
				name: gameName,
				data: new Array(locationsAmount).fill(0),
			}
		}

		return gameNameToIndex[gameName]
	}

	nonEmptyLocations.forEach((location, locationIndex) => {
		location.machineEvents.forEach((event) => {
			if (event.eventType !== 'stop') return

			const gameIndex = getOrCreateGameEntry(event.gameName)
			playedGames.values[gameIndex].data[locationIndex]++
		})
	})

	playedGames.values.sort((a, b) => a.name.localeCompare(b.name))

	return playedGames
}

const GamesPerLocationChart = () => {
	const stats = useCookedStatistics()
	const playedGames = useMemo(() => calculatePlayedGames(stats || []), [stats])

	const options = {
		chart: {
			type: 'bar',
			stacked: true,
			stackType: '100%',
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
			foreColor: '#ccc',
		},
		dataLabels: {
			enabled: false,
		},
		plotOptions: {
			bar: {
				horizontal: true,
			},
		},
		xaxis: {
			categories: playedGames.categories,
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		// colors: [
		//     '#fff100', //Process Yellow
		// 	'#ff8c00', //Orange 144
		// 	'#ec008c', //Process Magenta
		// 	'#68217a', //Purple 526
		// 	'#450b70',
		// 	'#9e35e0',
		// 	'#cf9fe5',
		// 	'#00188f', //Blue 286
		// 	'#00bcf2', //Process Cyan
		// 	'#00b294', //Teal 3275
		// 	'#009e49', //Green 355
		// 	// '#bac80a', //Lime 382
		// 	'#FEDA02',
		// 	'#52D75F',
		// 	'#01B0E7',
		// 	'#4060FC',
		// 	'#8A0AB7',
		// 	'#F93871',
		// 	'#802476',
		// 	'#6E53D9',
		// 	'#D522C6',
		// 	'#E0473D',
		// 	'#285588',
		// 	'#E36273',
		// 	'#FCC40F',
		// 	'#A8875E',
		// 	'#366767',
		// 	'#7558A4',
		// 	'#F4599D',
		// 	'#F5DF95',
		// 	'#EDA92D',
		// 	'#AC5B2C',
		// 	'#7A0080',
		// 	'#FFFF00',
		// 	'#30C030',
		// 	'#002CFF',
		// 	'#FF7E00',
		// 	'#7FFCD4',
		// 	'#C4FFF2',
		// ],
		tooltip: {
			theme: 'dark',
			y: {
				formatter: function (val) {
					return val + ' times played'
				},
			},
		},
		fill: {
			opacity: 1,
		},
		legend: {
			position: 'bottom',
		},
	}

	return <Chart options={options} series={playedGames.values} type='bar' />
}

export {GamesPerLocationChart}
