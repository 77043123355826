import {createContext, useContext, useState} from 'react'

const CookedStatisticsContext = createContext()
const CookedStatisticsDispatchContext = createContext()

const CookedStatisticsProvider = ({children}) => {
	const [cooked, setCooked] = useState(null)

	return (
		<CookedStatisticsDispatchContext.Provider value={setCooked}>
			<CookedStatisticsContext.Provider value={cooked}>{children}</CookedStatisticsContext.Provider>
		</CookedStatisticsDispatchContext.Provider>
	)
}

const useCookedStatistics = () => {
	const context = useContext(CookedStatisticsContext)
	if (context === undefined) {
		throw new Error('useCookedStatistics must be used within a StatisticsProvider')
	}

	return context
}

const useCookedStatisticsDispatch = () => {
	const context = useContext(CookedStatisticsDispatchContext)
	if (context === undefined) {
		throw new Error('useCookedStatisticsDispatch must be used within a StatisticsProvider')
	}

	return context
}

export {CookedStatisticsProvider, useCookedStatistics, useCookedStatisticsDispatch}
