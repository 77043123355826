import {React, useEffect, useState} from 'react'
import {SocketProvider, useSocket} from './context/SocketContext'
import {UserDataProvider} from './context/UserDataContext'
import {StatisticsProvider} from './context/StatisticsContext'
import {AdminDataProvider} from './context/AdminDataContext'
import {UserRole, useUser} from './context/UserContext'

const onlyWhen = (condition, Component) => (props) => condition ? <Component {...props} /> : props.children

const useInactivityTrigger = (ms, callback) => {
	const visibilityChange = 'visibilitychange'
	const [tabIsInactive, setTabIsInactive] = useState(false)

	useEffect(() => {
		const eventHandler = () => {
			setTabIsInactive(document.visibilityState === 'hidden')
		}
		document.addEventListener(visibilityChange, eventHandler)

		return () => {
			document.removeEventListener(visibilityChange, eventHandler)
		}
	}, [])

	useEffect(() => {
		if (!tabIsInactive) return

		const timeout = setTimeout(callback, ms)

		return () => {
			clearTimeout(timeout)
		}
	}, [tabIsInactive, ms, callback])
}

const InactivePageReload = () => {
	const socket = useSocket()
	const onLongInactive = () => {
		if (!socket || !socket.connected) {
			document.location.reload()
		}
	}

	const fiveMinutes = 5 * 60 * 1000

	useInactivityTrigger(fiveMinutes, onLongInactive)

	return null
}

const UpdatesProvider = ({children}) => {
	const user = useUser()

	const userIs = (role) => user.role === role
	const userIsNot = (role) => !userIs(role)

	const LocationUpdates = onlyWhen(userIsNot(UserRole.guest), UserDataProvider)
	const StatsUpdates = onlyWhen(userIsNot(UserRole.guest), StatisticsProvider)
	const AdminUpdates = onlyWhen(userIs(UserRole.admin), AdminDataProvider)

	return (
		<SocketProvider>
			<InactivePageReload />

			<LocationUpdates>
				<StatsUpdates>
					<AdminUpdates>{children}</AdminUpdates>
				</StatsUpdates>
			</LocationUpdates>
		</SocketProvider>
	)
}

export default UpdatesProvider
