import {useEffect} from 'react'

function isInputActive() {
	return document.activeElement.tagName.toLowerCase() === 'input'
}

const ForwardKeysToButtonClick = ({children, keys = []}) => {
	useEffect(() => {
		const listener = (event) => {
			if (!keys.includes(event.code) || isInputActive()) return
			event.preventDefault()
			children?.props.onClick?.()
		}
		document.addEventListener('keydown', listener)
		return () => {
			document.removeEventListener('keydown', listener)
		}
		// eslint-disable-next-line
	}, [children]) //, keys

	return <>{children}</>
}

export default ForwardKeysToButtonClick
