import {useAdminData} from '../../../context/AdminDataContext'
import React, {useMemo} from 'react'
import SelectList from '../../Common/SelectList'
import {useLocationsDictionary} from '../hooks/useLocationsDictionary'

const useEnumeratedLocations = () => {
	const [db] = useAdminData()
	const locationDictionary = useLocationsDictionary()

	return useMemo(() => {
		const locations = db.locations
		const locationKeys = [null, ...Object.keys(locations)]
		return locationKeys.map((id) => ({value: id, label: id === null ? 'None' : locationDictionary[id]}))
	}, [db, locationDictionary])
}

const SelectLocationForMachine = ({machineId, onSelect}) => {
	const [db] = useAdminData()

	const locations = useEnumeratedLocations()

	return (
		<SelectList
			options={locations}
			selectedByDefault={db.machines[machineId].location || null}
			id={'locations'}
			onSelect={onSelect}
		/>
	)
}

export {SelectLocationForMachine}
