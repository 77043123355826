import {useMemo} from 'react'
import Chart from 'react-apexcharts'
import {useCookedStatistics} from '../../../../context/CookedStatisticsContext'
import MachineService from '../../../../services/MachineService'

function zeros(n) {
	return new Array(n).fill(0)
}

// function addTotalCategory(stats) {
// 	const maxCols = stats.values.reduce((prevLength, curr) => Math.max(prevLength, curr.data.length), 0)

// 	const sumArrays = (a, b) => a.map((value, i) => value + (b[i] || 0))

// 	stats.total = {
// 		values: stats.values.reduce((prev, curr) => sumArrays(prev, curr.data), zeros(maxCols)),
// 		index: stats.values.push({name: 'Total', data: zeros(maxCols)}) - 1,
// 	}

// 	return stats
// }

const machineNames = Object.values(MachineService.MachineType)
const machineNameIndices = Object.fromEntries(machineNames.map((name, index) => [name, index]))

function calculatePlayedGames(stats) {
	/*
{
    "categories": [
        "Location 1",
        ...
        "Location n"
    ],
    "values": [
        {
            "name": "DreamSimulator",
            "data": [x1, ..., n1]
        },
        {
            "name": "VRMotorcycle",
            "data": [x2, ..., n2]
        },
        {
            "name": "VRShotgun",
            "data": [x3, ..., n3]
        },
        {
            "name": "HighScream",
            "data": [x4, ..., n4]
        },
        {
            "name": "VRDinosaur",
            "data": [x5, ..., n5]
        }
    ]
}
*/
	const nonEmptyLocations = stats.filter((entry) => entry.machineEvents.length > 0)

	const locationsNum = nonEmptyLocations.length

	const playedGames = {
		categories: [],
		values: machineNames.map((name) => ({name: name, data: zeros(locationsNum)})),
	}

	nonEmptyLocations.forEach((location, locationIndex) => {
		playedGames.categories[locationIndex] = location.locationName
		location.machineEvents.forEach(({eventType, machineCode}) => {
			if (eventType !== 'stop') return
			const machineName = MachineService.machineCodeToName(machineCode)
			const machineIndex = machineNameIndices[machineName]
			playedGames.values[machineIndex].data[locationIndex]++
		})
	})

	return playedGames /* addTotalCategory(playedGames) */
}

const SimulatorsUsedChart = () => {
	const stats = useCookedStatistics()
	const playedGames = useMemo(() => calculatePlayedGames(stats || []), [stats])

	const options = {
		chart: {
			type: 'bar',
			stacked: true,
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
			fontFamily: 'Roboto, sans-serif',
			foreColor: '#ccc',
		},
		dataLabels: {
			enabled: true,
			textAnchor: 'middle',
			formatter: (val, opt) => {
				if (playedGames.total && opt.seriesIndex === playedGames.total.index) {
					return Math.floor(playedGames.total.values[opt.dataPointIndex])
				}
				if (val === 0) return ''
				return val
			},
		},
		plotOptions: {
			bar: {
				borderRadius: 0,
				horizontal: true,
				dataLabels: {
					position: 'center',
				},
			},
		},
		xaxis: {
			categories: playedGames.categories,
		},

		yaxis: {
			showForNullSeries: false,
		},
		tooltip: {
			theme: 'dark',
		},
		legend: {
			showForZeroSeries: false,
		},
		grid: {
			show: false,
		},
		noData: {
			text: 'Loading...',
		},
	}

	return <Chart options={options} series={playedGames.values} type='bar' className='chart-total' />
}

export {SimulatorsUsedChart}
