import {createContext, useContext, useEffect, useState} from 'react'
import DataService from '../services/DataService'
import {useTopic} from './SocketContext'
import {useUserStorage} from '../utils/useUserStorage'
import {useAsync} from '../utils/useAsync'

const LocationsStateContext = createContext()

const nullMachinesBugfix = (db) => {
	Object.values(db.locations).forEach((location) => {
		if (!location.machines) location.machines = []
	})
}

const USER_DATA_LOCAL_STORAGE_KEY = 'userData'
const shouldUseStorage = false

const UserDataProvider = ({children}) => {
	// console.log('log to find redraw bug - UserDataProvider 1')

	const [saveData, loadData] = useUserStorage(USER_DATA_LOCAL_STORAGE_KEY)
	const updates = useTopic('userUpdate')

	// console.log('testing user daa providere log')
	const [db, setDB] = useState(shouldUseStorage ? loadData() : null)
	const [isLoading, setIsLoading] = useState(db === null)

	const handleLoaded = () => {
		setIsLoading(false)
	}

	const asyncFn = useAsync()

	useEffect(() => {
		// console.log('log to find redraw bug - UserDataProvider useEffect 1')

		asyncFn(DataService.userGetAll())
			.then((data) => {
				if (shouldUseStorage) {
					// console.log('log to find redraw bug - UserDataProvider useEffect 1.1')

					saveData(data)
				}
				setDB(data)
			})
			.catch(console.log)
			.finally(handleLoaded)
		//if i put asyncFn or saveData here it might be causing infinite loop
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		// console.log('log to find redraw bug - UserDataProvider useEffect 2')
		if (!updates) return

		console.log('updates, socket', updates)
		setDB((prevDB) => {
			// console.log('log to find redraw bug - UserDataProvider useEffect 2.1')

			const newDB = {}
			const categories = ['locations', 'machines'] //, 'clover'

			categories.forEach((category) => {
				const data = {...prevDB?.[category], ...updates[category]}
				for (const key in data) {
					if (data[key] == null) {
						delete data[key]
					}
				}
				newDB[category] = data
			})

			nullMachinesBugfix(newDB)

			if (shouldUseStorage) {
				// console.log('log to find redraw bug - UserDataProvider useEffect 2.3')

				saveData(newDB)
			}

			return newDB
		})
		// eslint-disable-next-line
	}, [updates])

	return <LocationsStateContext.Provider value={[db, isLoading]}>{children}</LocationsStateContext.Provider>
}

const useUserData = () => {
	const context = useContext(LocationsStateContext)
	if (context === undefined) {
		throw new Error('useUserData must be used within a UserDataProvider')
	}

	return context
}

export {useUserData, UserDataProvider}
