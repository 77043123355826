import ServicePageTemplate from './ServicePageTemplate'

const NotFound = () => (
	<ServicePageTemplate title={'Error 404'}>The page you are looking for was not found</ServicePageTemplate>
)

const Support = () => (
	<ServicePageTemplate title={'Need Support?'}>
		Email us at <a href='mailto:vr360action@gmail.com'>vr360action@gmail.com</a>
	</ServicePageTemplate>
)

const Privacy = () => (
	<ServicePageTemplate title={'Privacy Policy'}>
		Open{' '}
		<a
			href='https://www.termsfeed.com/live/308f5dcd-4de3-4f43-b21b-b65adfc4d3eb'
			rel='external nofollow noopener noreferrer'
			target='_blank'>
			Privacy Policy
		</a>
	</ServicePageTemplate>
)

const Terms = () => (
	<ServicePageTemplate title={'Terms of Use'}>
		Open{' '}
		<a
			href='https://www.termsfeed.com/live/f7cf54fb-84dd-43b1-876b-0d3ad89c8952'
			rel='external nofollow noopener noreferrer'
			target='_blank'>
			Terms of Use
		</a>
	</ServicePageTemplate>
)

const Offline = () => (
	<ServicePageTemplate title={"You're Offline"}>The page you are looking for was not found</ServicePageTemplate>
)

export {NotFound, Support, Privacy, Terms, Offline}
