const UserService = {
	roles: {
		guest: 'guest',
		user: 'user',
		vip: 'vip',
		admin: 'admin',
	},
}

export default UserService
