import './OTP.css';
import appstorebutton from './appstore.png';
import googlePlayButton from './google-play-badge.png';
const OTPAppButtons = () => {
    const openGooglePlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2', '_blank');
    };

    const openAppleAppStore = () => {
        window.open('https://apps.apple.com/us/app/google-authenticator/id388497605', '_blank');
    };

    return (
        <div>
            <button className="otp-button" onClick={openGooglePlayStore}>
                <img src={googlePlayButton} alt="Get it on Google Play" />
            </button>
            <button className="otp-button" onClick={openAppleAppStore}>
                <img src={appstorebutton} alt="Download on the App Store" />
            </button>
        </div>
    );
};

export default OTPAppButtons;
