import MachineView from './MachineView'
import {CustomCard} from '../../Common/CustomCard/CustomCard'
import {useUserData} from '../../../context/UserDataContext'
import {Collapse, Tooltip, OverlayTrigger} from 'react-bootstrap'
import React, {useEffect, useMemo, useState} from 'react'
import {ChevronDownIcon, ChevronUpIcon, InfoIcon} from '@primer/octicons-react'
import { useIsAdmin, useUser } from '../../../context/UserContext'
import {isToday, timeSincePrettyString} from '../../../utils/timeUtils'
// import {ActivityChart} from './ActivityChart'
import './LocationView.css'

const useSavedState = (defaultValue, category, key) => {
    if (!key || !category) {
        throw new Error('Invalid arguments')
    }

    const parent = JSON.parse(localStorage.getItem(category)) || {}
    // const parent = useMemo(() => JSON.parse(localStorage.getItem(category)) || {}, [category])
    const stored = parent[key]

    const [value, setValue] = useState(stored !== undefined ? stored : defaultValue)

    useEffect(() => {
        parent[key] = value
        localStorage.setItem(category, JSON.stringify(parent))
        // eslint-disable-next-line
    }, [value]) // [value, category, key, parent])

    return [value, setValue]
}

const useLocationStats = (id) => {
    const [db] = useUserData()

    return useMemo(() => {
        const location = db.locations[id]

        const thisMachines = location.machines.map((machineId) => db.machines[machineId])

        const todayMachines = thisMachines.filter((machine) => isToday(machine.activeUntil))
        const activeMachines = thisMachines.filter((machine) => Date.parse(machine.activeUntil) > Date.now()).length

        const ips = thisMachines.map((machine) => machine.lastKnownIp).filter((v) => !!v)

        const fullGamesToday = todayMachines.reduce((total, machine) => total + machine.fullGamesToday, 0)
        const partialGamesToday = todayMachines.reduce((total, machine) => total + machine.partialGamesToday, 0)

        // const clover = location.cloverMerchant ? db.clover[location.cloverMerchant] : {devices: []}
        // const cloverDevices = clover.devices.filter((cloverDevice) => location.cloverDevices.includes(cloverDevice.id))
        // const paymentsToday = Math.floor(
        // 	cloverDevices.reduce((total, cloverDevice) => total + cloverDevice.paymentsToday, 0) / 100
        // )
        // const refundsToday = Math.floor(
        // 	cloverDevices.reduce((total, cloverDevice) => total + cloverDevice.refundsToday, 0) / 100
        // )

        const lastActivity = thisMachines.reduce((max, machine) => Math.max(max, Date.parse(machine.activeUntil)), null)

        return {
            activeMachines,
            ips,
            fullGamesToday,
            partialGamesToday,
            // paymentsToday,
            // refundsToday,
            lastActivity,
        }
    }, [db, id])
}

const LocationTooltip = ({id}) => {
    const stats = useLocationStats(id)

    const isAdmin = useIsAdmin()
    const ips = Array.from(new Set(stats.ips))

    const IpList = () => {
        return ips.length > 1 ? (
            <ul>
                {' '}
                {`IP list (${ips.length}):`}
                {ips.map((ip, index) => (
                    <li key={index}>{ip}</li>
                ))}
            </ul>
        ) : (
            `Location IP: ${ips[0] || 'unknown'}`
        )
    }
    const user = useUser()

    return (
        <>
            {isAdmin && <IpList/>}
            {isAdmin && <br/>}
            {isAdmin && user.ip && 'Current IP: ' + user.ip}
            {isAdmin && user.ip && <br />}
            Partial games played: {stats.partialGamesToday}

            {/* <br />
			Refunds today: {stats.refundsToday}$ */}
        </>
    )
}

const TooltipButton = ({id}) => (
    <OverlayTrigger
        disabled='true'
        placement='auto'
        overlay={
            <Tooltip>
                <LocationTooltip id={id}/>
            </Tooltip>
        }>
        {({ref, ...etc}) => (
            <button ref={ref} {...etc} className='location-header-tooltip-button'>
                <InfoIcon size={16} verticalAlign='middle'/>
            </button>
        )}
    </OverlayTrigger>
)

const LocationView = ({id}) => {
    // console.log('log to find redraw bug - LocationView 1')

    const [db] = useUserData()
    const [openMachines, setOpenMachines] = useSavedState(false, 'openedLocations', id)

    const toggleMachines = () => {
        setOpenMachines(!openMachines)
    }

    // Bugfix: when default state is expanded machine view and collapsed chart,
    // chart takes 100% of parent size which is zero, so chart needs to be forcefully rerendered
    // when machine view collapse
    //
    // If openMachines is true (machine view is expanded by default and chart has zero size),
    // set shouldRemount to true. If not, rerender will not be used
    //
    // Rerender will happen when chart expand animation ended using Collapse#mountOnEnter callback,
    // then shouldRemount will be disabled
    //
    // const [shouldRemount, setShouldRemount] = useState(openMachines)
    // const disableRemount = () => {
    //     console.log('log to find redraw bug - LocationView disableRemount 1')

    //     if (shouldRemount) {
    //         console.log('log to find redraw bug - LocationView disableRemount 2')
    //         setShouldRemount(false)
    //     }
    // }

    //
    // End bugfix description

    const stats = useLocationStats(id)

    const activeUntil_prettyString = timeSincePrettyString(stats.lastActivity)

    const location = db.locations[id]
    const totalMachines = location.machines.length
    const locationIsEmpty = totalMachines === 0

    let now = Date.now()
    let timePassed = now - stats.lastActivity
    const unixMinute = 60 * 1000
    const minutesPassedSinceActive = timePassed / unixMinute
    // const timespanInMinutes = db.locations[id].machineEvents?.timespan
    //     ? db.locations[id].machineEvents.timespan / (60 * 1000)
    //     : 0
    const timespanInMinutes = 1

    return (
        <CustomCard.Item className='custom-card-item-location'>
            <CustomCard.Header className='custom-card-header-location'>
                <div className='location-header-summary'>
                    {location.name}
                    {!openMachines && !locationIsEmpty && ` (${stats.activeMachines}/${totalMachines})`}
                </div>

                <div className='location-header-controls'>
                    {!locationIsEmpty && <TooltipButton id={id} />}

                    <span className='location-header-spacer' />

                    <button
                        className='location-header-collapse-button'
                        disabled={locationIsEmpty}
                        onClick={toggleMachines}>
                        {openMachines ? (
                            <ChevronUpIcon size={16} verticalAlign='middle' />
                        ) : (
                            <ChevronDownIcon size={16} verticalAlign='middle' />
                        )}
                    </button>
                </div>
            </CustomCard.Header>

            <div className='location-machines-container-wrapper'>
                <Collapse in={openMachines}>
                    <div>
                        <div className='location-machines-container'>
                            {location.machines
                                .sort((l, r) => db.machines[l].machineCode - db.machines[r].machineCode)
                                .map((machineId) => (
                                    <MachineView key={machineId} id={machineId} />
                                ))}
                        </div>
                    </div>
                </Collapse>

                <div className='location-activity-wrapper'>
                    <div className='location-activity-text-wrapper'>
                        <div className='location-activity-text'>
                            {stats.activeMachines === 0 && minutesPassedSinceActive > timespanInMinutes
                                ? `Last active: ${activeUntil_prettyString}`
                                : stats.activeMachines === 0
                                ? 'Active in last minute'
                                : 'Active'}
                        </div>
                    </div>
                </div>
            </div>

            <CustomCard.Footer className='custom-card-footer-location'>
                <div>{`Played today: ${stats.fullGamesToday}`}</div>

                {/* <div>{`Earned today: ${stats.paymentsToday}$`}</div> */}
            </CustomCard.Footer>
        </CustomCard.Item>
    )
}

export default LocationView
