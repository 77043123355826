import DropdownFilterableList from '../DropdownFilterableList'
import {Dropdown, Table} from 'react-bootstrap'
import {useCallback, useMemo, useState} from 'react'
import './CustomTable.css'

const HeaderOptions = ({options, onChange}) => {
    const [currentOption, setCurrentOption] = useState(0)

    const handleChange = useCallback(
        (key) => {
            setCurrentOption(key)
            onChange(key)
        },
        [onChange]
    )

    return (
        <div className='table-header-appendix'>
            <DropdownFilterableList
                header={options[currentOption] || ''}
                settings={{enableFilter: false}}
                onSelect={handleChange}>
                {options.map((option, index) => (
                    <Dropdown.Item key={index} eventKey={index} active={index === +currentOption}>
                        {option || 'Missing'}
                    </Dropdown.Item>
                ))}
            </DropdownFilterableList>
        </div>
    )
}

function nonEmpty(array) {
    return array?.length > 0
}

function selectedFromRules(rules) {
    const selected = new Array(rules.length).fill(null)

    selected.forEach((_, index) => {
        const rule = rules[index]

        selected[index] = {
            sort: nonEmpty(rule.sorters) ? 0 : null,
            filter: nonEmpty(rule.filters) ? 0 : null,
            test: null,
        }
    })

    return selected
}

function createConfig(rules, selected) {
    const config = new Array(rules.length).fill(null)

    config.forEach((_, index) => {
        const rule = rules[index]
        const selection = selected[index]

        const sorters = rule.sorters
        const hasSort = nonEmpty(sorters)
        const sort = hasSort ? sorters[selection.sort || 0].sort : null

        const filters = rule.filters
        const hasFilter = nonEmpty(filters)
        const filter = hasFilter ? filters[selection.filter || 0].filter : null

        const testFn = rule.test
        const hasTest = !!testFn
        const needTest = !!selection.test
        const test = hasTest && needTest ? (value) => testFn(selection.test, value) : null

        config[index] = {sort, filter, test}
    })

    return config
}

const CustomTable = ({data, rules, specialRows}) => {
    const renderCell = (cellData, index) => rules[index].cellWidget(cellData)
    const hasHeaders = rules.find((rule) => !!rule.header) !== undefined

    // TODO: update when rules prop change
    // [{sort: key, filter: key, test: '****'}, ...]
    const [selected, setSelected] = useState(rules && selectedFromRules(rules))

    // useEffect(() => {
    //     setSelected(selectedFromRules(rules))
    // }, [rules])

    const config = useMemo(() => {
        return createConfig(rules, selected)
    }, [rules, selected])

    const handleChangeSelected = useCallback(
        (index, type, value) =>
            setSelected((prev) => {
                const update = [...prev]
                update[index][type] = value
                return update
            }),
        []
    )

    const handleSortChange = useCallback(
        (index, value) => {
            handleChangeSelected(index, 'sort', value)
        },
        [handleChangeSelected]
    )

    const handleFilterChange = useCallback(
        (index, value) => {
            handleChangeSelected(index, 'filter', value)
        },
        [handleChangeSelected]
    )

    const handleTestChange = useCallback(
        (index, value) => {
            handleChangeSelected(index, 'test', value)
        },
        [handleChangeSelected]
    )

    const sorted = useMemo(() => {
        const item = config.find((item) => !!item.sort)
        return item === undefined ? data : data.sort(item.sort)
        // eslint-disable-next-line
    }, [data, rules, config])

    const filtered = useMemo(() => {
        return config.reduce((current, {filter}) => (!!filter ? current.filter(filter) : current), sorted)
        // eslint-disable-next-line
    }, [sorted, rules, config])

    const final = useMemo(() => {
        return config.reduce((tested, {test}) => (!!test ? tested.filter(test) : tested), filtered)
        // eslint-disable-next-line
    }, [filtered, rules, config])

    return (
        <Table striped bordered>
            {hasHeaders && (
                <thead>
                    <tr>
                        {rules.map((rule, index) => (
                            <th key={rule.header}>
                                {rule.header || ''}

                                {rule.subHeader ? (
                                    <div
                                        style={{
                                            fontSize: '18px',
                                            fontStyle: 'normal',
                                            fontVariant: 'all-small-caps',
                                            fontWeight: 'normal',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}>
                                        <span>{rule.subHeader}</span>
                                        <span style={{}}>[10]</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {nonEmpty(rule.sorters) && (
                                        <HeaderOptions
                                            options={rule.sorters.map((sorter) => sorter.name)}
                                            onChange={(key) => {
                                                handleSortChange(index, key)
                                            }}
                                        />
                                    )}
                                    {nonEmpty(rule.filters) && (
                                        <HeaderOptions
                                            options={rule.filters.map((filter) => filter.name)}
                                            onChange={(key) => {
                                                handleFilterChange(index, key)
                                            }}
                                        />
                                    )}
                                    {rule.test && (
                                        <input
                                            type='text'
                                            className='my-2 form-control table-search'
                                            placeholder='Search...'
                                            onChange={(e) => {
                                                handleTestChange(index, e.target.value)
                                            }}
                                        />
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
            )}
            <tbody>
                {specialRows?.()}
                {final.map((value) => (
                    <tr key={value}>
                        {rules.map((_, colIndex) => (
                            <td key={colIndex}>{renderCell(value, colIndex)}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export {CustomTable}
