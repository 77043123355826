import React, {useCallback, useEffect, useState} from 'react'
import {useAdminData} from '../../../context/AdminDataContext'

import DataService from '../../../services/DataService'
import {useAsync} from '../../../utils/useAsync'
import {ConfirmationWindow} from '../Utils/ConfirmationWindow'
import {MachineLocationsTable} from './MachineLocationsTable'


function add(machineId, locationId) {
	return DataService.addMachineToLocation({
		machineId: machineId,
		locationId: locationId,
	})
}

function move(machineId, locationId) {
	return DataService.addMachineToLocationForce({
		machineId: machineId,
		locationId: locationId,
	})
}

function remove(machineId) {
	return DataService.clearMachineLocation({machineId: machineId})
}

function moveMachineFromTo(machineId, prevLocationId, nextLocationId) {
	if (!prevLocationId) {
		return add(machineId, nextLocationId)
	} else if (!nextLocationId) {
		return remove(machineId)
	} else {
		return move(machineId, nextLocationId)
	}
}

const MoveMachinesToLocations = () => {
	const [db] = useAdminData()
	const asyncFn = useAsync()

	const [showConfirmation, setShowConfirmation] = useState(false)
	const handleClose = () => {
		setShowConfirmation(false)
	}

	const [error, setError] = useState(null)

	const [isLoading, setIsLoading] = useState(false)
	const handleLoaded = () => {
		setIsLoading(false)
	}

	const [data, setData] = useState(null)

	const onMove = (machineId, locationsId) => {
		setData({locationId: locationsId, machineId: machineId})
	}

	const handleConfirm = useCallback(() => {
		const {locationId, machineId} = data
		const prevLocationId = db.machines[machineId].location

		setIsLoading(true)

		asyncFn(moveMachineFromTo(machineId, prevLocationId, locationId))
			.then(handleClose)
			.catch(setError)
			.finally(handleLoaded)
		//missing asyncFn
		// eslint-disable-next-line
	}, [db, data])

	useEffect(() => {
		setError(null)
		setIsLoading(false)
	}, [showConfirmation])

	useEffect(() => {
		if (!data) return
		setShowConfirmation(true)
	}, [data])

	return (
		<>
			<MachineLocationsTable onMove={onMove} />
			<ConfirmationWindow
				show={showConfirmation}
				isLoading={isLoading}
				error={error}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	)
}

export {MoveMachinesToLocations}

