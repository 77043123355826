import {createContext, useCallback, useContext, useEffect, useState} from 'react'

import io from 'socket.io-client'
import Config from '../Config'
import {getToken, useUser, useUserLoggedIn, useLogout} from './UserContext'

const SocketContext = createContext()

const SocketProvider = ({children}) => {
	const user = useUser()
	const isUserLoggedIn = useUserLoggedIn()
	const [socket, setSocket] = useState(null)

	const createSocket = useCallback(() => {
		const builtSocket = io(Config.socketUrl, {
			query: {},
			transports: ['websocket'],
			auth: {'x-access-token': getToken()},
			reconnection: true,
			path: '/ws',
		})

		builtSocket.on('connect', () => {
			setSocket(builtSocket)
		})

		builtSocket.on('disconnect', (reason) => {
			setSocket(null)
		})

		builtSocket.connect()

		return builtSocket
		// eslint-disable-next-line
	}, [user])

	useEffect(() => {
		if (!isUserLoggedIn) {
			return
		}

		const builtSocket = createSocket()
		return () => {
			builtSocket.disconnect()
		}
	}, [isUserLoggedIn, createSocket])

	return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
}

const useSocket = () => {
	const context = useContext(SocketContext)
	if (context === undefined) {
		throw new Error('useSocket must be used within a SocketContext')
	}

	return context
}

const useTopic = (topic) => {
	const socket = useSocket()
	const [data, setData] = useState(null)
	const logout = useLogout()

	useEffect(() => {
		if (!socket || !topic) return
		socket.on(topic, setData)

		socket.on('logout', () => {
			logout()
		})
		// eslint-disable-next-line
	}, [socket, topic])

	return data
}

export {useTopic, useSocket, SocketProvider}
