import axios from 'axios'
import Config from '../Config'
import {getToken} from '../context/UserContext'

const createRequest = (method, path, errorMap) => {
	return (data) => {
		return axios({
			method: method,
			url: Config.apiUrl + path,
			data: data,
			headers: {'x-access-token': getToken()},
		})
			.then((response) => response.data)
			.then(
				(response) => Promise.resolve(response),
				(error) => {
					if (!error.response) return Promise.reject('Network error')

					const serverErrorMessage = error.response.data?.error?.message
					if (serverErrorMessage && typeof serverErrorMessage === 'string') {
						return Promise.reject(serverErrorMessage)
					}

					const errorCode = error.response.status
					return Promise.reject((errorMap && errorMap[errorCode]) || `Unknown error ${errorCode}`)
				}
			)
	}
}

const requestFactory = {}
;['post', 'patch'].forEach((method) => {
	requestFactory[method] = (path, errorMap) => createRequest(method, path, errorMap)
})

export default requestFactory
