import {Col, Nav, Row, Tab} from 'react-bootstrap'
import './AdminTabs.css'
import {useEffect, useState} from 'react'

const AdminTabs = ({tabs}) => {
    const setPageHash = (id) => (window.location.hash = id)
    const [activeKey, setActiveKey] = useState('0')

    useEffect(() => {
        const hashChangeHandler = () => {
            const hash = window.location.hash.substring(1) // Remove the '#'
            const index = tabs.findIndex((tab) => tab.id === hash)
            if (index !== -1) {
                // Set the active tab if the hash matches an existing tab id
                // Add logic to handle defaultActiveKey based on the hash
                setActiveKey(index.toString())
            }
        }

        window.addEventListener('hashchange', hashChangeHandler)

        // Set the default active key based on the initial hash
        hashChangeHandler()

        return () => {
            window.removeEventListener('hashchange', hashChangeHandler)
        }
    }, [tabs])

    return (
        <div className='admin-tabs'>
            <Tab.Container defaultActiveKey={0} activeKey={activeKey}>
                <Row>
                    <Col className='sidebar-column'>
                        <Nav variant='pills' className='flex-column'>
                            <div className='sticky-top'>
                                {tabs.map((entry, index) => {
                                    const pageHash = entry.id
                                    return (
                                        <Nav.Item key={index}>
                                            <Nav.Link
                                                className='nav-menu'
                                                eventKey={index}
                                                onClick={() => setPageHash(pageHash)}>
                                                {entry.name}
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                })}
                            </div>
                        </Nav>
                    </Col>
                    <Col>
                        <Tab.Content>
                            {tabs.map((entry, index) => (
                                <Tab.Pane key={index} eventKey={index} id={entry.id}>
                                    <entry.component id={entry.id} />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}

export default AdminTabs
