import {useAdminData} from '../../../context/AdminDataContext'
import './GameView.css'

import React from 'react'

// import {XCircleIcon} from '@primer/octicons-react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
// import DataService from '../../../services/DataService'

import {timeSincePrettyString} from '../../../utils/timeUtils'
import {CustomTable} from '../../Common/CustomTable/CustomTable'
// import ConfirmationModal from '../Utils/ConfirmationModal'
// import MachineService from '../../../services/MachineService'

const GameWidget = ({
    id,
    imageUrl,
    imageSizeX,
    imageSizeY,
    machinesLongName,
    description,
    latestVersion,
    releaseDate,
    gameTime,
    gameAutoPause,
    gamePackage,
    category,
    notes,
    gameEngine,
    createdAt,
    updatedAt,
    name,
}) => {
    const dateTooltip = (props) => {
        const {date} = props
        return (
            <Tooltip {...props}>
                <>{date}</>
            </Tooltip>
        )
    }
    const textTooltip = (props) => {
        const {text} = props
        return (
            <Tooltip {...props}>
                <>{text}</>
            </Tooltip>
        )
    }
    // const imageTooltip = (props) => {
    //     return (
    //         <Tooltip {...props}>
    //             <>
    //                 <img src={imageUrl} alt={name} style={{maxWidth: '100%'}} />
    //             </>
    //         </Tooltip>
    //     )
    // }
    // const renderMachineTooltips = () => {
    //     return machines.map((machineName, index) => {
    //         return (
    //             <OverlayTrigger key={index} placement='auto' overlay={(props) => iconTooltip({...props, machineName})}>
    //                 {({ref, ...triggerHandler}) => (
    //                     <div {...triggerHandler} ref={ref}>
    //                         {machineName}
    //                     </div>
    //                 )}
    //             </OverlayTrigger>
    //         )
    //     })
    // }

    // const iconTooltip = (props) => {
    //     const {machineName} = props
    //     return (
    //         <Tooltip {...props}>
    //             <img
    //                 src={MachineService.machineNameToImage(machineName)}
    //                 alt={machineCode}
    //                 className='tooltip-machine-image'
    //             />
    //         </Tooltip>
    //     )
    // }
    const userData = (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div className='img-wrapper'>
                <img src={imageUrl} alt={name} />
            </div>
            <div style={{marginLeft: '10px', maxWidth: '100%'}}>
                <OverlayTrigger placement='auto' overlay={(props) => textTooltip({...props, text: `🆔 ${id}`})}>
                    {({ref, ...triggerHandler}) => (
                        <div {...triggerHandler} ref={ref}>
                            {`💬 Name: ${name}`}
                        </div>
                    )}
                </OverlayTrigger>
                <div style={{maxWidth: '500px', margin: '15px 0'}}>
                    {description ? `📚 Description: ${description}` : '📚 No description available'}
                </div>
                {/* {latestVersion ? `Version: ${latestVersion}` : null}
                {releaseDate ? `Release Date: ${releaseDate}` : null}
                <br /> */}

                {machinesLongName ? (
                    <div className='machines'>
                        Machines:
                        <ul style={{marginLeft: '20px'}}>
                            {machinesLongName.map((machineName, index) => (
                                <li key={index}>
                                    👉 {machineName}
                                    {/* {renderMachineTooltips(machineName)} */}
                                </li>
                            ))}
                        </ul>
                        <br />
                    </div>
                ) : null}
                {category && category.length > 0 ? (
                    <div className='category'>
                        Category:
                        <ul style={{marginLeft: '20px'}}>
                            {category.map((categoryName, index) => (
                                <li key={index}>
                                    🔠 {categoryName.trim()}
                                    {/* {renderMachineTooltips(machineName)} */}
                                </li>
                            ))}
                        </ul>
                        <br />
                    </div>
                ) : null}
                {notes ? (
                    <div className='notes'>
                        📝 {notes} <br />
                    </div>
                ) : null}
                <div className='gameEngine'>
                    🦾 Engine: {gameEngine ? gameEngine : 'Unknown'}
                    <br />
                </div>
                {gameTime ? (
                    <div className='gameAutoPause'>
                        ⏰ Game Time: {gameTime} seconds
                        <br />
                    </div>
                ) : null}
                {gameAutoPause > 0 ? (
                    <div className='gameAutoPause'>
                        ⛔️ Initial load time: {gameAutoPause} seconds <br />
                    </div>
                ) : null}

                {gamePackage ? (
                    <div className='gamePackage'>
                        <br />
                        Game package: {gamePackage}
                        <br />
                    </div>
                ) : null}
                {/* <OverlayTrigger placement='auto' overlay={(props) => dateTooltip({...props, date: createdAt})}>
                    {({ref, ...triggerHandler}) => (
                        <div {...triggerHandler} ref={ref}>
                            {`Created: ${timeSincePrettyString(createdAt)}`}
                        </div>
                    )}
                </OverlayTrigger> */}
                {updatedAt && (
                    <OverlayTrigger placement='auto' overlay={(props) => dateTooltip({...props, date: updatedAt})}>
                        {({ref, ...triggerHandler}) => (
                            <div {...triggerHandler} ref={ref} className='updated'>
                                {`Updated: ${timeSincePrettyString(updatedAt)}`}
                            </div>
                        )}
                    </OverlayTrigger>
                )}
            </div>
        </div>
    )

    return userData
}

const removeEmptyItems = (obj) => {
    const newObj = {}
    Object.keys(obj).forEach((key) => {
        if (key !== '' && Object.keys(obj[key]).some((k) => obj[key][k] !== '')) {
            newObj[key] = obj[key]
        }
    })
    return newObj
}

const GameView = () => {
    const [db] = useAdminData()
    const games = removeEmptyItems(db.games) || {}
    console.log(games)
    const gamesRule = {
        header: 'Games',
        subHeader: (
            <a href='https://docs.google.com/spreadsheets/d/1Wu6nUWVnrVheWX2SopIIHQ7UWkzYyr8sd8cz4pFBR3M/edit#gid=2063258179'>
                Google Link
            </a>
        ),
        accessor: 'games',
        cellWidget: (game) => {
            return (
                <GameWidget
                    name={games[game].name}
                    id={games[game].id}
                    imageUrl={games[game].imageUrl}
                    imageSizeX={games[game].imageSizeX}
                    imageSizeY={games[game].imageSizeY}
                    machinesLongName={games[game].machinesLongName}
                    description={games[game].description}
                    latestVersion={games[game].latestVersion}
                    releaseDate={games[game].releaseDate}
                    gamePackage={games[game].gamePackage}
                    gameTime={games[game].gameTime}
                    gameAutoPause={games[game].autoPauseTime}
                    category={games[game].category}
                    notes={games[game].notes}
                    gameEngine={games[game].gameEngine}
                    createdAt={games[game].createdAt}
                    updatedAt={games[game].updatedAt}
                />
            )
        },
        sorters: [
            {
                name: 'By name',
                sort: (l, r) => games[l].name.localeCompare(games[r].name),
            },
            // {
            //     name: 'By location amount ↓',
            //     sort: (l, r) => games[l].locations.length - games[r].locations.length,
            // },
            // {
            //     name: 'By location amount ↑',
            //     sort: (l, r) => games[r].locations.length - games[l].locations.length,
            // },
            // {
            //     name: 'By active sessions ↓',
            //     sort: (l, r) => games[l].refreshTokens.length - games[r].refreshTokens.length,
            // },
            // {
            //     name: 'By active sessions ↑',
            //     sort: (l, r) => games[r].refreshTokens.length - games[l].refreshTokens.length,
            // },
            {
                name: 'By creation date ↓',
                sort: (l, r) => Date.parse(games[l].createdAt) - Date.parse(games[r].createdAt),
            },
            {
                name: 'By creation date ↑',
                sort: (l, r) => Date.parse(games[r].createdAt) - Date.parse(games[l].createdAt),
            },
            {
                name: 'By last updated ↓',
                sort: (l, r) => Date.parse(games[l].updatedAt) - Date.parse(games[r].updatedAt),
            },
            {
                name: 'By last updated ↑',
                sort: (l, r) => Date.parse(games[r].updatedAt) - Date.parse(games[l].updatedAt),
            },
        ],
        filters: [
            {
                name: 'Show all',
                filter: () => true,
            },
            {
                name: 'Filter no description',
                filter: (game) => games[game].description !== '',
            },
            // {
            //     name: 'Show VIP',
            //     filter: (user) => games[user].role === 'vip',
            // },
            // {
            //     name: 'Show User',
            //     filter: (user) => games[user].role === 'user',
            // },
        ],
        test: (enteredText, game) =>
            Object.values(games[game]).join(' ').toLowerCase().includes(enteredText.toLowerCase()),
    }

    // const userRoleRule = {
    //     header: 'Roles',
    //     accessor: 'games',
    //     cellWidget: (user) => <EditUserRole userId={user} userName={games[user].username} role={games[user].role} />,
    //     filters: [
    //         {
    //             name: 'Show all',
    //             filter: () => true,
    //         },
    //         {
    //             name: 'Show Admin',
    //             filter: (user) => games[user].role === 'admin',
    //         },
    //         {
    //             name: 'Show VIP',
    //             filter: (user) => games[user].role === 'vip',
    //         },
    //         {
    //             name: 'Show User',
    //             filter: (user) => games[user].role === 'user',
    //         },
    //     ],
    // }

    // const userActionRule = {
    //     header: 'Actions',
    //     accessor: 'games',
    //     cellWidget: (user) => <RemoveUser userId={user} userName={games[user].username} />,
    // }
    const handlePrint = () => {
        window.print()
    }
    const PrintButton = () => {
        return (
            <button id='print-button' onClick={handlePrint}>
                Print Table
            </button>
        )
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <PrintButton />

            <div id='print-table'>
                <CustomTable data={Object.keys(games)} rules={[gamesRule]} />
            </div>
        </div>
    ) //userRoleRule, userActionRule
}

export {GameView}
