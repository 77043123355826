import {useRef} from 'react'
import {useLogin} from '../../context/UserContext'
import BasicAuthPage from './BasicAuthPage'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import AuthService from '../../services/AuthService'

const Login = () => {
	const login = useLogin()
	const loginData = useRef(null)

	return (
		<BasicAuthPage
			title='Sign in'
			buttonText='Submit'
			onSubmit={async ({email, password}) => {
				loginData.current = await AuthService.login(email.trim(), password)
			}}
			postSubmit={() => {
				const token = loginData.current?.token
				token && login(token)
			}}
			footer={
				<>
					<Link to='/register'>Create </Link> new account or <Link to='/restore'>restore</Link> existing
				</>
			}
			forms={[
				{
					title: 'Email or Username',
					placeholder: 'Email or Username',
					type: 'text',
					name: 'email',
					value: '',
					validator: Yup.string().required('Email is required'),
				},
				{
					title: 'Password',
					placeholder: 'Password',
					type: 'password',
					name: 'password',
					value: '',
					validator: Yup.string().required('Password is required'),
				},
			]}
		/>
	)
}

export default Login
