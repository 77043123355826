import {useAdminData} from '../../../context/AdminDataContext'
import {useMemo} from 'react'

const useLocationsDictionary = () => {
	const [db] = useAdminData()

	return useMemo(() => {
		const locations = db.locations
		const locationKeys = Object.keys(locations)
		const locationKeyValuePairs = locationKeys.map((id) => [id, `${locations[id].name}, ${locations[id].address}`])
		return Object.fromEntries(locationKeyValuePairs)
	}, [db])
}

export {useLocationsDictionary}
