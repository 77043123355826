import './CustomCard.css'

const CustomCardElementFactory = (className) => (props) =>
	(
		<div {...props} className={`${className} ${props.className || ''}`}>
			{props.children}
		</div>
	)

const CustomCard = {
	Item: CustomCardElementFactory('custom-card-item'),
	Header: CustomCardElementFactory('custom-card-header'),
	Body: CustomCardElementFactory('custom-card-body'),
	Footer: CustomCardElementFactory('custom-card-footer'),
}

export {CustomCard}
