import {useLocationsDictionary} from '../hooks/useLocationsDictionary'
import {useAdminData} from '../../../context/AdminDataContext'
import {useLocationsUsers} from './useLocationsUsers'
import {UsersView} from './UsersView'
import {CustomTable} from '../../Common/CustomTable/CustomTable'

const LocationsAndUsersTable = ({onAdd, onRemove}) => {
	const [db] = useAdminData()
	const locationsUsers = useLocationsUsers()
	const locationPrettyNames = useLocationsDictionary()

	const locationRules = {
		header: 'Locations',
		cellWidget: (id) => (
			<>
				{locationPrettyNames[id]}
				<br />
				Has {db.locations[id].machines.length} machines
			</>
		),
		test: (enteredText, id) => locationPrettyNames[id].toLowerCase().includes(enteredText.toLowerCase()),
		sorters: [
			{
				name: 'By name',
				sort: (l, r) => locationPrettyNames[l].localeCompare(locationPrettyNames[r]),
			},
			{
				name: 'By amount of users',
				sort: (l, r) => locationsUsers[l].length - locationsUsers[r].length,
			},
			{
				name: 'By time created',
				sort: (l, r) => Date.parse(db.locations[l].createdAt) - Date.parse(db.locations[r].createdAt),
			},
			{
				name: 'By last update',
				sort: (l, r) => Date.parse(db.locations[l].updatedAt) - Date.parse(db.locations[r].updatedAt),
			},
		],
	}

	const userRules = {
		header: 'Users',
		cellWidget: (locationId) => (
			<UsersView
				locationId={locationId}
				onAdd={(userId) => onAdd(userId, locationId)}
				onRemove={(userId) => onRemove(userId, locationId)}
			/>
		),
		filters: [
			{
				name: 'Show all',
				filter: () => true,
			},
			{
				name: 'Show empty',
				filter: (location) => locationsUsers[location].length === 0,
			},
			{
				name: 'Show non empty',
				filter: (location) => locationsUsers[location].length > 0,
			},
		],
		//todo: add here a filter to show all locations, but only the names in the filter should appear in the list (to make it easy to add or remove a specific user from multiple locations)
		// test: (enteredText, locationId) => locationsUsers[locationId]?.toLowerCase().includes(enteredText.toLowerCase()),
		// test: (enteredText, id) => {/**todo: add filter to show only searched username from the filter in the list of users for each location */},
	}

	return <CustomTable data={Object.keys(db.locations)} rules={[locationRules, userRules]} />
}

export {LocationsAndUsersTable}
