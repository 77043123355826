import MachineService from '../../../services/MachineService'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import React, {useState, useEffect} from 'react'
import {useUserData} from '../../../context/UserDataContext'
import './MachineView.css'
import {CircleIndicator} from '../../Common/CircleIndicator/CircleIndicator'
import { useIsAdmin, useUser } from '../../../context/UserContext'
import {timeSincePrettyString} from '../../../utils/timeUtils'
// import { useUserStorage } from '../../../utils/useUserStorage'

const useMachine = (id) => {
    // console.log('log to find redraw bug - useMachine 1')
    const [userData] = useUserData()

    const [machine, setMachine] = useState(userData.machines[id])

    useEffect(() => {
        // console.log('log to find redraw bug - useMachine 2.useEffect')
        setMachine(userData.machines[id])
    }, [userData.machines, id])

    return machine
}

const MachineState = {
    unknown: 'unknown',
    active: 'active',
    inactive: 'inactive',
    longInactive: 'longInactive',
}

function closestTimestamp(...timestamps) {
    const now = new Date().getTime()
    return timestamps.reduce((closest, timestamp) =>
        Math.abs(timestamp - now) < Math.abs(closest - now) ? timestamp : closest
    )
}

function getMachineLatestDate(machine) {
    /** machine = { ...
	"activeUntil": "2023-03-27T01:00:37.000Z",
	"updatedAt": "2023-03-27T01:00:38.019Z",
	"lastProgramStart": "2021-12-16T04:42:46.856Z",
	"turnedOnAt": "2021-12-16T04:42:46.856Z",
	"createdAt": "2021-12-16T04:42:46.859Z",
	} */
    const mActiveUntil = new Date(machine.activeUntil).getTime()
    const mTurnedOnAt = new Date(machine.turnedOnAt).getTime()
    const mUpdatedAt = new Date(machine.updatedAt).getTime()
    const mLastProgramStart = new Date(machine.lastProgramStart).getTime()
    const closestActiveTime = closestTimestamp(mActiveUntil, mTurnedOnAt, mUpdatedAt, mLastProgramStart)
    // console.log('mLastProgramStart', timeSincePrettyString(mLastProgramStart))
    // console.log('mActiveUntil', timeSincePrettyString(mActiveUntil))
    // console.log('mTurnedOnAt', timeSincePrettyString(mTurnedOnAt))
    // console.log('mUpdatedAt', timeSincePrettyString(mUpdatedAt))
    // console.log('closestActiveTime', timeSincePrettyString(closestActiveTime))
	const isGameLast = mLastProgramStart !== Number(closestActiveTime)
	return { lastActive: closestActiveTime, isGameLastActicity: isGameLast }
}
function extractNumbersFromUUID(uuid) {
    const regex = /-(\d+)-/
    const match = uuid.match(regex)
    if (match && match.length > 1) {
        return match[1]
    }
    return null
}

const MachineView = ({id}) => {
    const machine = useMachine(id)
    // console.log('MachineView machine:', JSON.stringify(machine))
    const isAdmin = useIsAdmin()
    const image = MachineService.machineCodeToImage(machine.machineCode)

	const [turnOnTime, setTurnOnTime] = useState(timeSincePrettyString(machine.turnedOnAt))
	const { lastActive, isGameLastActicity } = getMachineLatestDate(machine)
	const [activeUntilTime, setActiveUntilTime] = useState(lastActive)
	const [activeUntilString, setActiveUntilString] = useState(timeSincePrettyString(activeUntilTime))
    const [activityStringPrefix, setActivityStringPrefix] = useState('Last active:') // Last active: || Active:
	const [lastActivityisGame, setLastActivityisGame] = useState(isGameLastActicity) // Played: <Game Name> || Software Started!

    const [machineState, setMachineState] = useState(MachineState.unknown)

	const AdminInfo = () => {
		const user = useUser()
		return (
        <>
            {`---admin---`} <br />
            {`Turned on: ${turnOnTime}`} <br />
				{'IP: ' + machine.lastKnownIp} <br />
				{'Current IP ' + user.ip} <br />      
            {'SW version: ' + machine.swVersion} <br />
            {'UUID extract: ' + extractNumbersFromUUID(machine.id)} <br />
        </>
		)
	}

    const renderTooltip = (props) => {
		setActiveUntilString(timeSincePrettyString(activeUntilTime))
        return (
			<Tooltip {...props}>
                {`${machine.machineType}: ${machine.machineCode}`} <br />
                Games today: {machine.fullGamesToday} ({machine.partialGamesToday} partial) <br />
                {activeUntilString && `${activityStringPrefix} ${activeUntilString}`}
                {activeUntilString && <br />}
				{lastActivityisGame ? 'Last Played: ' + machine.lastGameName : 'Software Started!'}
				<br />
                {isAdmin && <AdminInfo />}
            </Tooltip>
        )
    }

    useEffect(() => {
		setTurnOnTime(timeSincePrettyString(machine.turnedOnAt))

        // setActiveityString(timeSincePrettyString(machine.turnedOnAt))
        // console.log('log to find redraw bug - MachineView useEffect 1')
        const dateNow = Date.now()
        const activeUntil = Date.parse(machine.activeUntil)
        setActiveUntilTime(activeUntil)

        const timeFromLastGame = dateNow - activeUntil
        const wasActiveInLast24h = timeFromLastGame < 1000 * 24 * 60 * 60
		const { isGameLastActicity } = getMachineLatestDate(machine)

		setLastActivityisGame(isGameLastActicity)
        if (activeUntil > dateNow) {
            // console.log('log to find redraw bug - MachineView useEffect 1.active')
            setMachineState(MachineState.active)
            // the time is set to "now", so we dont need this text
            setActivityStringPrefix('Active:')
        } else if (wasActiveInLast24h) {
            // console.log('log to find redraw bug - MachineView useEffect 1.inactive')
            setMachineState(MachineState.inactive)
            setActivityStringPrefix('Last active:')
        } else {
            // console.log('log to find redraw bug - MachineView useEffect 1.long inactive')
            setMachineState(MachineState.longInactive)
            setActivityStringPrefix('Last active:')
        }
    }, [machine, activeUntilString])

    return (
        <OverlayTrigger placement='auto' overlay={renderTooltip}>
            {({ref, ...triggerHandler}) => (
                <div {...triggerHandler} ref={ref} className='machine'>
                    <div className='machine-image-wrapper'>
                        <img
                            key={image}
                            title={machine.machineCode}
                            src={image}
                            alt={machine.machineCode}
                            className={
                                machineState === MachineState.active ? 'machine-active-image' : 'machine-inactive-image'
                            }
                        />
                        {machineState === MachineState.longInactive && (
                            <div className='machine-long-inactive-text'>inactive for a long time</div>
                        )}
                    </div>

                    <CircleIndicator active={machineState === MachineState.active} />

                    <div className='machine-game-name'>
                        {machineState === MachineState.active && machine.lastGameName}
                    </div>
                </div>
            )}
        </OverlayTrigger>
    )
}

export default MachineView
