import jwt from 'jwt-decode'
import {createContext, useContext, useEffect, useState} from 'react'
import Config from '../Config'

import DataService from '../services/DataService'


const LOCAL_STORAGE_USER_KEY = 'user'

const UserRole = {
    guest: 'guest',
    user: 'user',
    vip: 'vip',
    admin: 'admin',
}

const NullUser = {
    details: {},
    token: '',
    role: UserRole.guest,
    ip: '',
}

const UserStateContext = createContext()
const UserDispatchContext = createContext()
const UserLoginStateContext = createContext()

function tokenToUser(token, ipAddress = null) {
const data = jwt(token)
return {
    details: {
        id: data.id,
        username: data.username,
        email: data.email,
    },
    token: token,
    role: data.role,
    ttl: data.exp * 1000,
    ip: ipAddress ? ipAddress : data.ip,
}

}

const UserProvider = ({children}) => {
    const [ipAddress, setIpAddress] = useState('')

    useEffect(() => {
        if (Config.isProd) {
            fetch('https://api.ipify.org/?format=json')
                .then((response) => response.json())
                .then((data) => setIpAddress(data.ip))
        } else setIpAddress('localhost')
    }, [])
    const loadedUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY)) || NullUser

const [user, dispatch] = useState(loadedUser)


const autoDispatch = (user = null) => {
    if (user) {
        localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user))
        dispatch(user)
    } else {
        localStorage.removeItem(LOCAL_STORAGE_USER_KEY)
        dispatch(NullUser)
    }
}


    useEffect(() => {
        if (user.role === UserRole.guest) {
            return
        }

const timeAheadMs = 5 * 60 * 1000
const now = Date.now()
const ttl = Math.max(user.ttl - timeAheadMs, now)


const timeout = setTimeout(async () => {
    try {
        const response = await DataService.updateUserToken({oldToken: user.token})
        const decodedUser = tokenToUser(response.token, ipAddress)
        autoDispatch(decodedUser)
    } catch (e) {
        autoDispatch()
    }
}, Math.max(ttl - now - timeAheadMs, 0))


        return () => {
            clearTimeout(timeout)
        }
    }, [user, ipAddress])

    return (
        <UserStateContext.Provider value={user}>
            <UserDispatchContext.Provider value={dispatch}>
                <UserLoginStateContext.Provider value={user.role !== UserRole.guest}>
                    {children}
                </UserLoginStateContext.Provider>
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    )
}

const useUser = () => {
const context = useContext(UserStateContext)
if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
}


return context

}

const useUserDispatch = () => {
const context = useContext(UserDispatchContext)
if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
}


return context

}

const useLogin = () => {
const dispatch = useUserDispatch()


return (data) => {
    const user = tokenToUser(data)
    localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user))
    dispatch(user)
}

}

const useLogout = () => {
const dispatch = useUserDispatch()
return () => {
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY)
    dispatch(NullUser)
}

}

const useUserLoggedIn = () => {
const context = useContext(UserLoginStateContext)
if (context === undefined) {
    throw new Error('useUserLoggedIn must be used within a UserProvider')
}


return context

}

const getToken = () => {
const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY))
return user?.token || ''

}

const useIsAdmin = () => {
return useUser().role === UserRole.admin

}

export {UserProvider, UserRole, getToken, useIsAdmin, useLogin, useLogout, useUser, useUserLoggedIn}

