const ConfigMap = {
    development: {
        isProd: false,
        apiUrl: 'http://localhost:5027',
        socketUrl: `ws://localhost:5027`,
    },
    production: {
        isProd: true,
        apiUrl: '',
        socketUrl: '',
    },
}

const ConfigKey = process?.env.NODE_ENV || 'development'
const Config = ConfigMap[ConfigKey]

export default Config
