import React from 'react'
import {Alert, Button, Modal} from 'react-bootstrap'
import ForwardKeysToButtonClick from '../../Common/ForwardKeysToButtonClick'
import LoadingSpinner from '../../Common/LoadingSpinner'

const ConfirmationWindow = ({show, isLoading, error, onClose, onConfirm}) => (
    <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm action</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Alert variant='danger' show={!!error}>
                {error}
            </Alert>
        </Modal.Body>

        <Modal.Footer>
            <Button variant='danger' onClick={onClose}>
                Cancel
            </Button>

            <ForwardKeysToButtonClick keys={['Enter', 'Space']}>
                <Button variant='success' onClick={onConfirm} disabled={isLoading}>
                    {isLoading && <LoadingSpinner />} Save Changes
                </Button>
            </ForwardKeysToButtonClick>
        </Modal.Footer>
    </Modal>
)

export {ConfirmationWindow}
