import React, {useState} from 'react'
import {Form} from 'react-bootstrap'
import ConfirmationModal from '../Utils/ConfirmationModal'


const CreateOrEditLocationModal = ({show, onHide, onSubmit}) => {
	const [name, setName] = useState('')
	const [address, setAddress] = useState('')

	const onConfirm = () => onSubmit({name: name, address: address})

	const handleHide = () => {
		setName('')
		setAddress('')
		onHide()
	}

	return (
		<ConfirmationModal show={show} onHide={handleHide} title={'Create or edit location'} onConfirm={onConfirm}>
			<Form>
				<Form.Group className='form-group' controlId='formLocationName'>
					<Form.Label>Location name:</Form.Label>
					<Form.Control
						type='text'
						onChange={(value) => {
							setName(value.target.value)
						}}
					/>
					{!name && <div className='form-error-message'>*Enter valid location name</div>}
				</Form.Group>
				<Form.Group className='form-group' controlId='formLocationAddress'>
					<Form.Label>Location address:</Form.Label>
					<Form.Control
						type='text'
						onChange={(value) => {
							setAddress(value.target.value)
						}}
					/>
					{!address && <div className='form-error-message'>*Enter valid location address</div>}
				</Form.Group>
			</Form>
		</ConfirmationModal>
	)
}

export default CreateOrEditLocationModal
