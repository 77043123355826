import './ContentLoader.css'

const LoadingIndicator = ({type}) => (
	<span className='loading-indicator-wrapper'>
		<span className={`spinner-${type}`} />
	</span>
)

const ContentLoader = ({loading, children, type = 'border'}) => (loading ? <LoadingIndicator type={type} /> : children)

export {ContentLoader}
