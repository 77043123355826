import React, {useState} from 'react'
import BasicAuthPage from './BasicAuthPage'
import * as Yup from 'yup'
import {Link, Navigate} from 'react-router-dom'
import DataService from '../../services/DataService'

const ChangePassword = () => {
	const [passwordChangeResult, setPasswordChangeResult] = useState(false)

	if (passwordChangeResult) {
		return <Navigate to='/' />
	}

	return (
		<BasicAuthPage
			title='Change Password'
			buttonText='Submit'
			onSubmit={async ({password}) => {
				const params = new URL(window.location.href).searchParams
				// const token = params.get('token')
				// const userId = params.get('id')
				await DataService.setNewPassword({
					password,
					token: params.get('token'),
					userId: params.get('id'),
				}).then(() => {
					setPasswordChangeResult(true)
				})
			}}
			footer={
				<>
					Return to the <Link to='/login'>login page</Link>
				</>
			}
			forms={[
				{
					title: 'Password',
					placeholder: 'Password',
					type: 'password',
					name: 'password',
					value: '',
					validator: Yup.string()
						.min(8, 'Password must be between 8 and 30 characters')
						.max(30, 'Password must be between 8 and 30 characters')
						.required('Password is required'),
				},
				{
					title: 'Password',
					placeholder: 'Confirm password',
					type: 'password',
					name: 'passwordConfirmation',
					value: '',
					validator: Yup.string().required('Password is required'),
				},
			]}
		/>
	)
}

export default ChangePassword
