import React from 'react'
import {useAdminData} from '../../../context/AdminDataContext'
import {CustomTable} from '../../Common/CustomTable/CustomTable'
import {MachineView} from './MachineView'
import {SelectLocationForMachine} from './SelectLocationForMachine'
import {useLocationsDictionary} from '../hooks/useLocationsDictionary'

const MachineLocationsTable = ({onMove}) => {
	const [db] = useAdminData()
	const locationsDictionary = useLocationsDictionary()
	const machineRule = {
		header: 'Machine',
		cellWidget: (id) => <MachineView id={id} />,
		test: (enteredText, id) => db.machines[id].machineCode.includes(enteredText),
		sorters: [
			{
				name: 'By name',
				sort: (l, r) => db.machines[l].machineCode.localeCompare(db.machines[r].machineCode),
			},
			{
				name: 'By location',
				sort: (l, r) =>
					locationsDictionary[db.machines[l].location]?.localeCompare(locationsDictionary[db.machines[r].location]),
			},
			{
				name: 'By IP',
				sort: (l, r) => db.machines[l].lastKnownIp?.localeCompare(db.machines[r].lastKnownIp),
			},
			{
				name: 'By last seen ↑',
				sort: (l, r) => Date.parse(db.machines[l].activeUntil) - Date.parse(db.machines[r].activeUntil),
			},
			{
				name: 'By last seen ↓',
				sort: (l, r) => Date.parse(db.machines[r].activeUntil) - Date.parse(db.machines[l].activeUntil),
			},
			{
				name: 'By first seen ↑',
				sort: (l, r) => Date.parse(db.machines[l].createdAt) - Date.parse(db.machines[r].createdAt),
			},
			{
				name: 'By first seen ↓',
				sort: (l, r) => Date.parse(db.machines[r].createdAt) - Date.parse(db.machines[l].createdAt),
			},
		],
	}

	const locationRule = {
		header: 'Locations',
		cellWidget: (machineId) => (
			<SelectLocationForMachine
				machineId={machineId}
				onSelect={(locationId) => {
					onMove(machineId, locationId)
				}}
			/>
		),
		test: (enteredText, id) =>
			locationsDictionary[db.machines[id].location]?.toLowerCase().includes(enteredText.toLowerCase()),
		filters: [
			{
				name: 'Show all',
				filter: () => true,
			},
			{
				name: 'Show empty',
				filter: (machine) => !db.machines[machine].location,
			},
			{
				name: 'Show non empty',
				filter: (machine) => !!db.machines[machine].location,
			},
		],
	}

	return <CustomTable data={Object.keys(db.machines)} rules={[machineRule, locationRule]} />
}

export {MachineLocationsTable}
