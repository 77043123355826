import React from 'react'
import {StatisticsOptions} from './StatisticsOptions/StatisticsOptions'
import {CookedStatisticsProvider, useCookedStatistics} from '../../../context/CookedStatisticsContext'
import {TopGamesChart} from './Charts/TopGamesChart'
import {SimulatorsUsedChart} from './Charts/SimulatorsUsedChart'
import {GamesPerLocationChart} from './Charts/GamesPerLocationChart'
import {BusyHoursChart} from './Charts/BusyHoursChart'
// import {IncomeChart} from './Charts/IncomeChart'
import {OverallActivityChart} from './Charts/OverallActivityChart'
import {CustomCard} from '../../Common/CustomCard/CustomCard'
import './UserStatistics.css'

const Card = (props) => (
	<CustomCard.Item className={props.className}>
		<CustomCard.Header>{props.title || ''}</CustomCard.Header>

		<CustomCard.Body>{props.children}</CustomCard.Body>

		{props.note ? <CustomCard.Footer className={'chart-note'}>{props.note}</CustomCard.Footer> : null}
	</CustomCard.Item>
)

const ChartCard = ({title, note, children}) => (
	<Card title={title} note={note} className='custom-card-item-chart'>
		{children}
	</Card>
)

const ChartOptions = () => (
	<Card title={'Options'}>
		<StatisticsOptions />
	</Card>
)
const Charts = () => {
	const charts = [
		{chartName: 'Simulators Used', chart: <SimulatorsUsedChart />},
		// {chartName: 'Income', chart: <IncomeChart />, note: '* including accessories'},
		{chartName: 'Top Games', chart: <TopGamesChart />},
		{
			chartName: 'Gameplay Count',
			chart: <GamesPerLocationChart />,
			note: '* only counting games, not players',
		},
		{chartName: 'Busy Hours', chart: <BusyHoursChart />},
		{chartName: 'Overall Activity', chart: <OverallActivityChart />},
	]

	if (!useCookedStatistics()) {
		return null
	}

	return (
		<div className='charts-grid'>
			{charts.map(({chartName, chart, note}, index) => (
				<div key={index} className={'chart-wrapper chart' + index}>
					<ChartCard title={chartName} note={note}>
						{chart}
					</ChartCard>
				</div>
			))}
		</div>
	)
}

const UserStatistics = () => (
	<CookedStatisticsProvider>
		<ChartOptions />
		<div style={{marginTop: '2rem'}}></div>
		<Charts />
	</CookedStatisticsProvider>
)

export default UserStatistics
