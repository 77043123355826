import {useLogout, useUser} from '../context/UserContext'

const UserStorage = {
    load: (key, userId) => {
        try {
            const value = JSON.parse(localStorage.getItem(key))
            return value.__userId === userId ? value : null
        } catch {
            return null
        }
    },
    save: (key, value, userId) => {
        value.__userId = userId
        localStorage.setItem(key, JSON.stringify(value))
    },
}

const useUserStorage = (key) => {
    const user = useUser()
    const logout = useLogout()
    if (!user.details) logout()
    const id = user.details.id
    const load = () => UserStorage.load(key, id)
    const save = (value) => {
        const typeOfValue = typeof value
        if (typeOfValue !== 'object' && typeOfValue !== 'array') {
            throw new Error('Only reference type is supported')
        }

        UserStorage.save(key, value, id)
    }

    return [save, load]
}

export {useUserStorage}
