import {useMemo} from 'react'
import Chart from 'react-apexcharts'
import {useCookedStatistics} from '../../../../context/CookedStatisticsContext'

const GAMES_AMOUNT = 10

function calculateTopGames(stats) {
	const topGames = {}

	stats.forEach((location) => {
		location.machineEvents.forEach(({eventType, gameName, playPeople}) => {
			if (eventType !== 'stop') return

			if (topGames[gameName] === undefined) {
				topGames[gameName] = 0
			}

			topGames[gameName] += playPeople
		})
	})

	const sorted = Object.fromEntries(
		Object.keys(topGames)
			.map((key) => [key, topGames[key]])
			.sort((l, r) => r[1] - l[1])
	)

	return {
		categories: Object.keys(sorted).slice(0, GAMES_AMOUNT),
		values: [{data: Object.values(sorted).slice(0, GAMES_AMOUNT)}],
	}
}

const TopGamesChart = () => {
	const stats = useCookedStatistics()
	const topGames = useMemo(() => calculateTopGames(stats || []), [stats])

	const options = {
		chart: {
			type: 'bar',
			stacked: true,
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
			foreColor: '#ccc',
		},
		dataLabels: {
			enabled: false,
		},
		plotOptions: {
			bar: {
				horizontal: true,
			},
		},
		xaxis: {
			categories: topGames.categories,
		},
		legend: {
			position: 'bottom',
		},
		tooltip: {
			theme: 'dark',
			y: {
				title: {
					formatter: () => 'Average times played:',
				},
			},
		},
		colors: [
			({dataPointIndex}) => {
				return dataPointIndex < 3 ? '#00c896' : '#6c757d'
			},
		],
		noData: {
			text: 'Loading...',
		},
	}

	return <Chart options={options} series={topGames.values} type='bar' />
}

export {TopGamesChart}
