import React from 'react'
import BasicAuthPage from './BasicAuthPage'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import DataService from '../../services/DataService'

const Restore = () => (
	<BasicAuthPage
		title='Restore Password'
		buttonText='Submit'
		onSubmit={async ({email}) => await DataService.startPasswordRestore({email})}
		footer={
			<>
				Return to the <Link to='/login'>login page</Link>
			</>
		}
		forms={[
			{
				title: 'Email',
				placeholder: 'Email',
				type: 'text',
				name: 'email',
				value: '',
				validator: Yup.string().required('Email is required'),
			},
		]}
	/>
)

export default Restore
